import { ChangeEvent } from 'react';
import { getMonthsOptions } from '../../../../../../common/domain/date-time-provider';
import { setTransactionsMonthToStorage } from '../../../../domain/finance-storage';

interface ITransactionActionsMonthsDropDown {
    transactionsSelectedMonth: number;
    setTransactionsSelectedMonth: (_transactionsSelectedMonth: number) => void;
}

const TransactionActionsMonthsDropDown = ({
      transactionsSelectedMonth,
      setTransactionsSelectedMonth,
  }: ITransactionActionsMonthsDropDown) => {

    /**
     * Months are 0-indexed in JavaScript Date.
     */
    const onChange = (evt: ChangeEvent<HTMLSelectElement>) => {
        const value = Number(evt.target.value) || 0;
        setTransactionsSelectedMonth(value);
        setTransactionsMonthToStorage(value);
    };

    return (
        <select
            className="rounded bg-white border px-4 py-2"
            value={ transactionsSelectedMonth }
            onChange={ onChange }>
            { getMonthsOptions() }
        </select>
    )
};

export default TransactionActionsMonthsDropDown;