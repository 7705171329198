import { get, putJson } from '../../../common/data/common-data-service';
import { IOverviewResponse } from '../interfaces';

export const getOverview = async () : Promise<IOverviewResponse> => {
    return await get(`/api/overview/get-overview-data`);
};

export const removeTodoFromOverview = async (todo_id: number) : Promise<IOverviewResponse> => {
    return await putJson(`/api/overview/remove-todo-from-overview`, {
        todo_id,
    });
};

export const updateProjectDesc = async (project_id: number, new_desc: string) : Promise<IOverviewResponse> => {
    return await putJson(`/api/overview/update-project-desc`, {
        project_id,
        new_desc,
    });
};