import { useFinanceStore } from '../../../data/finance-store';
import FinanceCategory from './category';

const CategoriesList = () => {
    const financeCategoriesData = useFinanceStore(store => store.financeCategoriesData);

    return (
        <div>
            {
                financeCategoriesData?.categories?.map(category => {
                    return (
                        <FinanceCategory
                            key={ category.CategoryID }
                            category={ category }
                        />
                    )
                })
            }
        </div>
    )
};

export default CategoriesList;