import { ITodoSplitItem } from './interfaces';
import { format } from 'date-fns';

interface ITodoSplitItemProps {
    item: ITodoSplitItem;
    index: number;
}

const TodoSplitItem = ({ item, index }: ITodoSplitItemProps) => {
    return (
        <div
            className="mb-2 flex flex-col border-b pb-2 mb-2">
            <div className="flex items-center">
                <span className="mr-2">{ index + 1 }.</span>
                {
                    item.date &&
                    <strong className="mr-4">{ format(item.date, 'dd MMM, yyyy') }</strong>
                }

                <span>{ item.title }</span>
            </div>

            {
                item.description &&
                <div className="mt-2 text-gray-400 text-sm">{ item.description }</div>
            }
        </div>
    )
};

export default TodoSplitItem;