import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { CheckIcon, PreloaderIcon } from '../../../../common/ui/icons';
import { updateNote } from '../../data/planner-data-service';
import { showToast } from '../../../../common/domain/toast-provider';
import { combineProjectData } from '../../domain/planner-provider';
import { usePlannerStore } from '../../data/planner-store';

interface ITodayNoteProps {
    note: string;
}

const TodayNote = ({ note } : ITodayNoteProps) => {

    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);

    const updatedText = useRef<string>(note);
    const [isLoading, setLoading] = useState(false);
    const [renderCount, setRenderCount] = useState(0);

    useEffect(() => {
        updatedText.current = note;
        setRenderCount(count => count + 1);
        window.getSelection()?.removeAllRanges();
    }, [note]);

    const save = async () => {
        setLoading(true);
        const response = await updateNote(
            updatedText.current.trim(),
        );
        setLoading(false);

        if(!response) {
            showToast('Update note error.');
            return;
        }

        setCombinedProject(combineProjectData(response));
    };

    const onTextChange = (evt: ChangeEvent<HTMLDivElement>) => {
        const $target = (evt.target as HTMLDivElement);
        updatedText.current = $target.innerHTML;
    };

    const onKeyDown = async (evt: KeyboardEvent<HTMLDivElement>) => {
        if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
            evt.preventDefault();
            await save();
            return;
        }
    };

    return (
        <div className="text-sm leading-7 pb-2 mb-4">

            <div
                data-count={ renderCount }
                className="outline-none bg-white whitespace-pre-wrap"
                onInput={ onTextChange }
                onKeyDown={ onKeyDown }
                translate="no"
                aria-readonly="false"
                aria-multiline="true"
                role="textbox"
                contentEditable="true"
                dangerouslySetInnerHTML={{__html: updatedText.current || '' }} />

            <div className="flex items-center justify-end text-sm mt-2">
                {
                    isLoading &&
                    <PreloaderIcon size={ 24 } color={ '#717985' } />
                }

                {
                    !isLoading &&
                    <button
                        onClick={ save }
                        type="button">
                        <CheckIcon />
                    </button>
                }
            </div>
        </div>
    )
};

export default TodayNote;