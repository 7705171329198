import { PreloaderIcon, SquareRoundedCheckIcon } from '../../../../../common/ui/icons';
import { ITodo, TodoType } from '../../../planner-interfaces';
import { insertTodo } from '../../../data/planner-data-service';
import { showToast } from '../../../../../common/domain/toast-provider';
import { combineProjectData } from '../../../domain/planner-provider';
import { useState } from 'react';
import { addDays, startOfDay } from 'date-fns';
import { usePlannerStore } from '../../../data/planner-store';

interface ITrackYesterdayHabitBtn {
    todo: ITodo;
}

const TrackYesterdayHabitBtn = ({ todo }: ITrackYesterdayHabitBtn) => {

    const [trackHabitLoading, setTrackHabitLoading] = useState(false);

    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);

    const trackYesterdayHabit = async () => {
        if(trackHabitLoading || todo.habit_qty_per_interval === todo.habit_total) return;

        const yesterday = startOfDay(addDays(new Date, -1));

        setTrackHabitLoading(true);
        const response = await insertTodo(
            todo.project_id,
            todo.section_id,
            todo.todo_id,
            `Habit Log: ${ todo.todo_title }`,
            null,
            todo.todo_address,
            0,
            yesterday.getTime(),
            1,
            TodoType.RegularTaskLog,
            0,
            0,
            null,
            null,
            null,
            null,
            null,
            null,
            1,
        );
        setTrackHabitLoading(false);

        if(!response) {
            showToast('Track yesterday habit error.');
            return;
        }

        setCombinedProject(combineProjectData(response));

        showToast('Track yesterday habit is done.', false);
    };

    return (
        <button
            title="Track Yesterday Habit"
            className="todo__track-yesterday-habit flex bg-slate-200 rounded mx-1 text-left justify-center items-center whitespace-nowrap action-btn"
            onClick={ trackYesterdayHabit }
            type="button">

            {
                !trackHabitLoading &&
                <SquareRoundedCheckIcon color={ '#6e7781' } size={ 20 } />
            }

            {
                trackHabitLoading &&
                <PreloaderIcon size={ 18 } color={ '#2a8079' } />
            }
        </button>
    )
};

export default TrackYesterdayHabitBtn;