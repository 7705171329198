import { ResponsiveLine } from '@nivo/line';
import { ITodo } from '../../planner/planner-interfaces';
import { generateGraphData } from '../domain/habit-tracker-provider';
import { format } from 'date-fns';
import { useHabitTrackerStore } from '../data/habit-tracker-store';

interface IHabitChart {
    nested: ITodo[];
}

const renderTick = ({
        opacity,
        textAnchor,
        textBaseline,
        textX,
        textY,
        theme,
        value,
        x,
        y
        // eslint-disable-next-line
    }: any) => {

    const parts = value.split(' ');

    return (
        <g
            transform={`translate(${ x ?? 0 },${ y ?? 0 })`}
            style={{ opacity }}
        >
            <text
                alignmentBaseline={ textBaseline }
                style={ theme?.axis?.ticks?.text }
                textAnchor={ textAnchor }
                transform={`translate(${ textX ?? 0 },${ textY ?? 0 })`}
            >
                {
                    parts.map((part: string, i: number) => {
                        return (
                            <tspan
                                textAnchor="middle"
                                style={{
                                    fontSize: '12px',
                                    textAlign: 'center',
                                }}
                                key={ `${ i }-${ part }` }
                                x="0"
                                dy="1.2em">
                                { part }
                            </tspan>
                        )
                    })
                }
            </text>
        </g>
    )
};

const HabitChart = ({ nested }: IHabitChart) => {

    const habitTrackerStartDate = useHabitTrackerStore(store => store.habitTrackerStartDate);
    const [data, verticalTicks] = generateGraphData(habitTrackerStartDate, nested);

    return (
        <div className="overflow-auto w-full border bg-white py-4">
            <div style={{
                height: 200,
                minWidth: '500px',
            }}>
                <ResponsiveLine
                    data={ data }
                    margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
                    xScale={{ type: 'point' }}
                    yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
                    axisTop={ null }
                    axisRight={ null }
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        // legend: format(new Date(), 'MMMM yyyy'),
                        // legendOffset: 36,
                        // legendPosition: 'middle',

                        // tickValues: [], // No tick values
                        // format: () => '', // No labels on the ticks
                        renderTick,
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        tickValues: verticalTicks,
                    }}
                    colors={{ scheme: 'nivo' }}
                    pointSize={ 10 }
                    pointColor={{ theme: 'background' }}
                    pointBorderWidth={ 2 }
                    pointBorderColor={{ from: 'serieColor' }}
                    pointLabel="y"
                    pointLabelYOffset={ -12 }
                    useMesh={ true }
                    markers={[
                        // This is a "today" vertical line ----------------------------
                        {
                            axis: 'x',
                            value: format(new Date(), `dd EEEEEE`),
                            lineStyle: {
                                stroke: '#637a9d',
                                strokeWidth: 2
                            },
                            // legend: 'Today', // optional
                            // legendOrientation: 'vertical' // optional
                        }
                    ]}
                />
            </div>
        </div>
    )
};

export default HabitChart;