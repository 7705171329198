import { ChangeEvent } from 'react';
import { TransactionSource } from '../../../../finance-interfaces';

interface ITransactionSourceDropDown {
    transactionSource: number;
    setTransactionsSource: (_transactionSource: TransactionSource) => void;
}

const TransactionSourceDropDown = ({
      transactionSource,
      setTransactionsSource,
  }: ITransactionSourceDropDown) => {

    const onChange = (evt: ChangeEvent<HTMLSelectElement>) => {
        const value = Number(evt.target.value) || 0;
        setTransactionsSource(value as TransactionSource);
    };

    return (
        <select
            className="rounded bg-white border px-4 py-2"
            value={ transactionSource }
            onChange={ onChange }>
            <option value={ TransactionSource.BankLeumi }>Bank Leumi</option>
            <option value={ TransactionSource.Max8792 }>Leumi Card (Max) - Мой</option>
            <option value={ TransactionSource.Max8893 }>Leumi Card (Max) - Сашин</option>
            <option value={ TransactionSource.LeumiCardMax }>Leumi Card (Max) - Смешанные данные</option>
            <option value={ TransactionSource.IsraCard }>Isra Card</option>
            <option value={ TransactionSource.VisaCal5550 }>Visa Cal (Суперсаль) - Мой</option>
            <option value={ TransactionSource.VisaCal8910 }>Visa Cal (Суперсаль) - Сашин</option>
            <option value={ TransactionSource.VisaCal }>Visa Cal (Суперсаль) - Смешанные данные</option>
            <option value={ TransactionSource.Cash }>Наличные</option>
            <option value={ TransactionSource.CashCard }>Cash Card</option>
        </select>
    )
};

export default TransactionSourceDropDown;