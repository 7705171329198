import { IOverviewData, IOverviewResponse } from '../interfaces';
import { ITodo } from '../../planner/planner-interfaces';

export const formatOverviewData = (response: IOverviewResponse) : IOverviewData => {
    const projects = response.projects || [];
    const todos = response.todos || [];

    const projectTodosMap = new Map<number, ITodo[]>(); // project_id --> todos

    for(const todo of todos) {
        const _projectTodos = projectTodosMap.get(todo.project_id) || [];
        _projectTodos.push(todo);
        projectTodosMap.set(todo.project_id, _projectTodos);
    }

    for(const project of projects) {
        project.sections = [{
            section: null,
            todos: projectTodosMap.get(project.project_id) || [],
            isOpened: true,
        }];
    }

    return {
        projects,
    };
};