import { useState } from 'react';
import { DeleteIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { showToast } from '../../../../../common/domain/toast-provider';
import { IFood } from '../../../food-interfaces';
import { useFoodStore } from '../../../data/food-store';
import { deleteFood } from '../../../data/food-data-service';

interface IDeleteFood {
    food: IFood;
}

const DeleteFood = ({ food }: IDeleteFood) => {

    const [isLoading, setLoading] = useState(false);

    const setFoodItemsData = useFoodStore(store => store.setFoodItemsData);
    const foodItemsPageNumber = useFoodStore(store => store.foodItemsPageNumber);
    const foodItemsSelectedCategoryID = useFoodStore(store => store.foodItemsSelectedCategoryID);
    const foodItemsSearchTerm = useFoodStore(store => store.foodItemsSearchTerm);

    const deleteHandler = async () => {

        if(!confirm(`Are you sure you want to delete "${ food.name }"?`)) return;

        setLoading(true);
        const response = await deleteFood(
            food.id,
            foodItemsPageNumber,
            foodItemsSelectedCategoryID,
            foodItemsSearchTerm,
        );
        setLoading(false);

        if(!response) {
            showToast('Delete food error.');
            return;
        }

        setFoodItemsData(response);
    };

    return (
        <>
            {
                isLoading &&
                <PreloaderIcon size={ 20 } color={ '#717985' } />
            }

            {
                !isLoading &&
                <button
                    title="Delete Food"
                    className="food__delete flex bg-pink-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
                    onClick={ deleteHandler }
                    type="button">
                    <DeleteIcon size={ 20 } color="#BE185D" />
                </button>
            }
        </>
    )
};

export default DeleteFood;