import { useFinanceStore } from '../../data/finance-store';

export enum BusinessesTab {
    Default = 0,
    AddNew = 1,
}

const BusinessTabsNavigation = () => {

    const businessesTab = useFinanceStore(store => store.businessesTab);
    const setBusinessesTab = useFinanceStore(store => store.setBusinessesTab);

    const openListTab = () => {
        setBusinessesTab(BusinessesTab.Default);
    };

    const openAddNewTab = () => {
        setBusinessesTab(BusinessesTab.AddNew);
    };

    return (
        <nav className="flex border-b-8 mb-8 border-teal-700 rounded-r-md">
            <button
                onClick={ openListTab }
                className={ `border-t border-l border-r px-10 py-2 mr-2 rounded-t-md border-teal-700 ${ businessesTab === BusinessesTab.Default ? 'bg-teal-700 text-white' : '' }` }
                type="button">
                List
            </button>

            <button
                onClick={ openAddNewTab }
                className={ `border-t border-l border-r px-6 py-2 rounded-t-md border-teal-700 ${ businessesTab === BusinessesTab.AddNew ? 'bg-teal-700 text-white' : '' }` }
                type="button">
                Add New
            </button>
        </nav>
    )
};

export default BusinessTabsNavigation;