import { ChangeEvent, KeyboardEvent as ReactKeyboardEvent, useEffect, useRef, useState } from 'react';
import { handleWiziwig } from '../../../../planner/domain/wiziwig-provider';
import { PreloaderIcon } from '../../../../../common/ui/icons';
import { useFinanceStore } from '../../../data/finance-store';
import { insertBusiness } from '../../../data/finance-data-service';
import { showToast } from '../../../../../common/domain/toast-provider';
import { BusinessesTab } from '../tabs-navigation';

const AddNewBusiness = () => {

    const nameRef = useRef<HTMLInputElement>(null);

    const financeBusinessData = useFinanceStore(store => store.financeBusinessData);
    const setFinanceBusinessData = useFinanceStore(store => store.setFinanceBusinessData);
    const setBusinessesTab = useFinanceStore(store => store.setBusinessesTab);

    const [name, setName] = useState('');
    const [nameTouched, setNameTouched] = useState(false);

    const [categoryID, setCategoryID] = useState(0);
    const [categoryIDTouched, setCategoryIDTouched] = useState(false);

    const [description, setDescription] = useState('');
    const [isLoading, setLoading] = useState(false);

    const isNameValid = !nameTouched || name.trim().length > 0;
    const isCategoryIdValid = !categoryIDTouched || categoryID > 0;

    useEffect(() => {
        nameRef?.current?.focus();
    }, []);

    const save = async () => {

        if(name.trim().length <= 0) {
            setNameTouched(true);
            return;
        }

        if(categoryID <= 0) {
            setCategoryIDTouched(true);
            return;
        }

        setLoading(true);

        const response = await insertBusiness(
            name,
            description,
            categoryID,
        );

        setLoading(false);

        if(!response) {
            showToast('Insert business error.');
            return;
        }

        setFinanceBusinessData(response);
        setBusinessesTab(BusinessesTab.Default);
    };

    const onNameChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setName((evt.target as HTMLInputElement).value);
        setNameTouched(true);
    };

    const onDescChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
        setDescription((evt.target as HTMLTextAreaElement).value);
    };

    const onCategoryChange = (evt: ChangeEvent<HTMLSelectElement>) => {
        const _categoryID = Number((evt.target as HTMLSelectElement).value) ?? 0;
        setCategoryID(_categoryID);
        setCategoryIDTouched(true);
    };

    const onNameKeyDown = async (evt: ReactKeyboardEvent<HTMLInputElement>) => {
        if(handleWiziwig(evt, nameRef, (newText) => {
            setName(newText);
            setNameTouched(true);
        })){
            return;
        }

        if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
            evt.preventDefault();
            await save();
            return;
        }

        if(evt.code === 'Enter') {
            await save();
        }
    };

    const onDescKeyDown = async (evt: ReactKeyboardEvent<HTMLTextAreaElement>) => {

        if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
            evt.preventDefault();
            await save();
            return;
        }

        if(evt.code === 'Enter') {
            await save();
        }
    };

    return (
        <div>
            <label className="flex flex-col mb-4">
                <div className="font-bold text-slate-400 mb-2">Business Name</div>
                <input
                    ref={ nameRef }
                    value={ name }
                    onInput={ onNameChange }
                    onKeyDown={ onNameKeyDown }
                    className={ `border rounded px-4 py-2 ${ isNameValid ? 'outline-stone-200' : 'outline-red-200 border-red-200' }` }
                    type="text"
                />

                {
                    !isNameValid && <div className="text-red-700 text-xs mt-1">The name is required.</div>
                }
            </label>

            <label className="flex flex-col mb-4">
                <div className="font-bold text-slate-400 mb-2">Business Description</div>
                <textarea
                    value={ description }
                    onInput={ onDescChange }
                    onKeyDown={ onDescKeyDown }
                    className={ `border rounded px-4 py-2` }
                    rows={ 4 }
                />
            </label>

            <label className="flex flex-col mb-4">
                <div className="font-bold text-slate-400 mb-2">Business Category</div>

                <select
                    value={ categoryID }
                    onChange={ onCategoryChange }
                    className={ `border rounded px-4 py-2 bg-white ${ isCategoryIdValid ? 'outline-stone-200' : 'outline-red-200 border-red-200' }` }>
                    <option value={ 0 }>Select Category</option>
                    {
                        financeBusinessData?.categories?.map(category => {
                            return (
                                <option
                                    key={ category.CategoryID }
                                    value={ category.CategoryID }>{ category.CategoryName }</option>
                            )
                        })
                    }
                </select>

                {
                    !isCategoryIdValid && <div className="text-red-700 text-xs mt-1">The category is required.</div>
                }
            </label>

            <div className="flex items-center justify-end text-sm mt-4">

                {
                    isLoading &&
                    <PreloaderIcon size={ 24 } color={ '#717985' } />
                }

                {
                    !isLoading &&
                    <button
                        className="bg-slate-500 text-slate-100 rounded px-6 py-2"
                        onClick={ save }
                        type="button">Save</button>
                }
            </div>

        </div>
    )
};

export default AddNewBusiness;