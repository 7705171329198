import { PreloaderIcon } from '../../../../../common/ui/icons';

interface ITodoSplitButtons {
    save: () => void;
    resetDescription: () => void;
    split: () => void;
    isLoading: boolean;
}

const TodoSplitButtons = ({ save, resetDescription, split, isLoading }: ITodoSplitButtons) => {
    return (
        <div className="w-full bg-gray-100 px-4 py-3 flex justify-center items-center">

            {
                isLoading &&
                <PreloaderIcon
                    size={ 24 }
                    color={ '#717985' }
                    classes="mr-4"
                />
            }

            {
                !isLoading &&
                <button
                    onClick={ save }
                    type="button"
                    className="mr-4 bg-red-800 text-gray-100 px-10 py-2 rounded">
                    Save
                </button>
            }

            <button
                onClick={ resetDescription }
                type="button"
                className="mr-4 bg-gray-800 text-gray-100 px-10 py-2 rounded">
                Reset
            </button>

            <button
                onClick={ split }
                type="button"
                className="bg-gray-800 text-gray-100 px-10 py-2 rounded">
                Split
            </button>
        </div>
    )
};

export default TodoSplitButtons;