import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { IProject } from '../../planner/planner-interfaces';
import { useOverviewStore } from '../data/overview-store';
import { CheckIcon, PreloaderIcon } from '../../../common/ui/icons';
import { showToast } from '../../../common/domain/toast-provider';
import { formatOverviewData } from '../domain/overview-provider';
import { updateProjectDesc } from '../data/overview-data-service';

interface IProjectFreeText {
    project: IProject;
}

const ProjectFreeText = ({ project } : IProjectFreeText) => {

    const setOverviewData = useOverviewStore(store => store.setOverviewData);

    const updatedText = useRef<string>(project.project_desc);
    const [isLoading, setLoading] = useState(false);
    const [renderCount, setRenderCount] = useState(0);

    useEffect(() => {
        updatedText.current = project.project_desc;
        setRenderCount(count => count + 1);
        window.getSelection()?.removeAllRanges();
    }, [project]);

    const save = async () => {
        setLoading(true);
        const response = await updateProjectDesc(
            project.project_id,
            updatedText.current.trim(),
        );
        setLoading(false);

        if(!response) {
            showToast('Update project description error.');
            return;
        }

        setOverviewData(formatOverviewData(response));
    };

    const onTextChange = (evt: ChangeEvent<HTMLDivElement>) => {
        const $target = (evt.target as HTMLDivElement);
        updatedText.current = $target.innerHTML;
    };

    const onKeyDown = async (evt: KeyboardEvent<HTMLDivElement>) => {
        if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
            evt.preventDefault();
            await save();
            return;
        }
    };

    return (
        <div className="text-sm leading-7 pb-4 p-4 border mt-4">

            <div
                data-count={ renderCount }
                className="outline-none bg-white whitespace-pre-wrap"
                onInput={ onTextChange }
                onKeyDown={ onKeyDown }
                translate="no"
                aria-readonly="false"
                aria-multiline="true"
                role="textbox"
                contentEditable="true"
                dangerouslySetInnerHTML={{__html: updatedText.current || '' }} />

            <div className="flex items-center justify-end text-sm mt-2">
                {
                    isLoading &&
                    <PreloaderIcon size={ 24 } color={ '#717985' } />
                }

                {
                    !isLoading &&
                    <button
                        onClick={ save }
                        type="button">
                        <CheckIcon />
                    </button>
                }
            </div>
        </div>
    )
};

export default ProjectFreeText;