import YearsDropDown from './drop-downs/years-drop-down';
import MonthsDropDown from './drop-downs/months-drop-down';
import CategoriesRadioFilters from './radio-filters/categories-radio-filters';
import { useFinanceStore } from '../../../data/finance-store';
import SearchTransactions from '../list/search';

const TopActionsAndData = () => {

    const financeTransactionsData = useFinanceStore(store => store.financeTransactionsData);

    const income = -1 * (financeTransactionsData?.incomeSum ?? 0);
    const expenses = financeTransactionsData?.expensesSum ?? 0
    const expensePercent = ((income !== 0) ? (100 * expenses / income) : 0).toFixed(2);
    const rest = income - expenses;
    const restPercent = ((income !== 0) ? (100 * rest / income) : 0).toFixed(2);

    return (
        <div className="flex flex-col">
            <div className="flex flex-wrap items-center mb-4">
                <MonthsDropDown />
                <YearsDropDown />

                <span className="text-sm mr-4 bg-green-200 px-4 py-2 rounded mb-2 lg:mb-0 inline-block"><strong>Доходы:</strong> { Math.abs(income).toLocaleString() } ₪</span>
                <span className="text-sm mr-4 bg-sky-200 px-4 py-2 rounded mb-2 lg:mb-0 inline-block"><strong>Расходы:</strong> { Math.abs(expenses).toLocaleString() } ₪ ({ expensePercent }%)</span>
                <span className="text-sm mr-4 bg-yellow-200 px-4 py-2 rounded mb-2 lg:mb-0 inline-block"><strong>Остаток:</strong> { Math.abs(rest).toLocaleString() } ₪ ({ restPercent }%)</span>
            </div>

            <CategoriesRadioFilters />

            <SearchTransactions />
        </div>
    )
};

export default TopActionsAndData;