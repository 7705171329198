import { useState } from 'react';
import { DeleteIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { showToast } from '../../../../../common/domain/toast-provider';
import { IFoodLog } from '../../../food-interfaces';
import { useFoodStore } from '../../../data/food-store';
import { deleteFoodLog } from '../../../data/food-data-service';
import { endOfDay, startOfDay } from 'date-fns';

interface IDeleteFoodLog {
    foodLog: IFoodLog;
}

const DeleteFoodLog = ({ foodLog }: IDeleteFoodLog) => {

    const [isLoading, setLoading] = useState(false);

    const foodLogDate = useFoodStore(store => store.foodLogDate);
    const setFoodLogData = useFoodStore(store => store.setFoodLogData);

    const deleteHandler = async () => {

        if(!confirm(`Are you sure you want to delete food log "${ foodLog.name }"?`)) return;

        setLoading(true);

        const fromDate = startOfDay(foodLogDate).getTime();
        const toDate = endOfDay(foodLogDate).getTime();

        const response = await deleteFoodLog(
            fromDate,
            toDate,
            foodLog.id,
        );
        setLoading(false);

        if(!response) {
            showToast('Delete food log error.');
            return;
        }

        setFoodLogData(response);
    };

    return (
        <>
            {
                isLoading &&
                <PreloaderIcon size={ 20 } color={ '#717985' } />
            }

            {
                !isLoading &&
                <button
                    title="Delete Food Log"
                    className="transaction__delete flex bg-pink-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
                    onClick={ deleteHandler }
                    type="button">
                    <DeleteIcon size={ 20 } color="#BE185D" />
                </button>
            }
        </>
    )
};

export default DeleteFoodLog;