import { SplitIcon } from '../../../../../common/ui/icons';
import { ITodo } from '../../../planner-interfaces';
import { usePlannerStore } from '../../../data/planner-store';

interface ITodoSplitBtn {
    todo: ITodo;
}

const TodoSplitBtn = ({ todo }: ITodoSplitBtn) => {

    const setSplitPopupTodo = usePlannerStore(state => state.setSplitPopupTodo);

    const onClick = () => {
        setSplitPopupTodo(todo);
    };

    return (
        <button
            onClick={ onClick }
            type="button"
            title="Split Todo"
            className="todo__split flex bg-slate-200 rounded mx-1 text-left justify-center items-center whitespace-nowrap action-btn">
            <SplitIcon />
        </button>
    )
};

export default TodoSplitBtn;