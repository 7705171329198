import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { SearchIcon } from '../../../../../common/ui/icons';
import { useFinanceStore } from '../../../data/finance-store';

const SearchBusinesses = () => {
    const businessSearchTerm = useFinanceStore(store => store.businessSearchTerm);
    const setBusinessSearchTerm = useFinanceStore(store => store.setBusinessSearchTerm);
    const setBusinessesPageNumber = useFinanceStore(store => store.setBusinessesPageNumber);
    const financeBusinessData = useFinanceStore(state => state.financeBusinessData);

    const [text, setText] = useState(businessSearchTerm);

    useEffect(() => {
        setText(businessSearchTerm);
    }, [businessSearchTerm]);

    const onChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setText(evt.target.value);
    };

    const startSearch = () => {
        setBusinessSearchTerm(text);
        setBusinessesPageNumber(0);
    };

    const onKeyUp = (evt: KeyboardEvent<HTMLInputElement>) => {
        if(evt.code === 'Escape') {
            setText('');
        }

        if(evt.code === 'Enter') {
            startSearch();
        }
    };

    return (
        <div className="flex flex-col mb-4">

            <div className="flex items-start">
                <div className="flex flex-col flex-1 mr-4">
                    <input
                        className="flex-1 border rounded outline-none px-4 py-2 mb-2"
                        type="text"
                        placeholder="Search businesses..."
                        value={ text }
                        onInput={ onChange }
                        onKeyUp={ onKeyUp }
                    />
                    <div className="text-sm text-right">{ financeBusinessData?.total ?? 0 } results</div>
                </div>

                <button
                    onClick={ startSearch }
                    className="flex bg-teal-600 rounded-xl justify-center items-center"
                    type="button">
                    <SearchIcon color={ '#efefef' } size={ 40 } />
                </button>
            </div>
        </div>
    )
};

export default SearchBusinesses;