import { ArrowLeftIcon, ArrowRightIcon, CalendarIcon } from '../../../../common/ui/icons';
import { useFoodStore } from '../../data/food-store';
import { addDays, format } from 'date-fns';

const FoodLogHeader = () => {

    const foodLogDate = useFoodStore(store => store.foodLogDate);
    const setFoodLogDate = useFoodStore(store => store.setFoodLogDate);

    const date = new Date(foodLogDate);
    const weekDayName = format(date, 'EEEE');
    const formatted = `${ format(date, 'dd MMM, yyyy') } - ${ weekDayName }`;

    const changeToPrevDay = () => {
        const prev = addDays(date, -1);
        setFoodLogDate(prev.getTime());
    };

    const changeToNextDay = () => {
        const next = addDays(date, 1);
        setFoodLogDate(next.getTime());
    };

    return (
        <h1 className="text-xl xl:text-3xl border-b border-stone-300 pb-2 flex items-center">
            <CalendarIcon
                classes="mr-4"
                size={ 35 }
                color={ '#bb9d9d' }
                circleColor={ '#fff6f3' }
            />

            <span className="flex items-center">
                    Food Log <span className="text-xs md:text-xl text-violet-800 mx-4">{ formatted }</span>
                </span>

            {/*<span className="text-sm font-normal mx-4">{ totalTodosCount }</span>*/}

            <div className="flex items-center ml-auto">
                <button type="button" onClick={ changeToPrevDay }>
                    <ArrowLeftIcon />
                </button>

                <button type="button" onClick={ changeToNextDay }>
                    <ArrowRightIcon />
                </button>
            </div>

        </h1>
    )
};

export default FoodLogHeader;