import { ChangeEvent } from 'react';

interface ITodoSplitFields {
    separator: string;
    onSeparatorChange: (evt: ChangeEvent<HTMLInputElement>) => void;
    dateFormat: string;
    onDateFormatChange: (evt: ChangeEvent<HTMLInputElement>) => void;
    useMainTodoTitle: boolean;
    onUseMainTodoTitleChange: (evt: ChangeEvent<HTMLInputElement>) => void;
    count: number;
}

const TodoSplitFields = ({
                             separator,
                             onSeparatorChange,
                             dateFormat,
                             onDateFormatChange,
                             useMainTodoTitle,
                             onUseMainTodoTitleChange,
                             count,
}: ITodoSplitFields) => {
    return (
        <div className="grid grid-cols-2 gap-4">
            <label className="flex flex-col">
                <span className="block mb-1 text-gray-400 text-sm">Separator</span>
                <input
                    value={ separator }
                    onChange={ onSeparatorChange }
                    type="text"
                    className="border rounded w-full px-4 py-2"
                />
            </label>

            <label className="flex flex-col">
                <span className="block mb-1 text-gray-400 text-sm">Date Format</span>
                <input
                    value={ dateFormat }
                    onChange={ onDateFormatChange }
                    type="text"
                    className="border rounded w-full px-4 py-2"
                />
            </label>

            <label className="flex items-center">
                <input
                    type="checkbox"
                    checked={ useMainTodoTitle }
                    onChange={ onUseMainTodoTitleChange }
                    className="mr-2"
                />
                <span className="block text-gray-400 text-sm">Use main title</span>
            </label>

            {
                !!count &&
                <div className="text-gray-400 text-sm">{ count } items(s)</div>
            }
        </div>
    )
};

export default TodoSplitFields;