import Actions, { sendCloseActionsEvent } from '../../../../../common/ui/actions';
import { Fragment } from 'react';
import { IFood } from '../../../food-interfaces';
import { useFoodStore } from '../../../data/food-store';
import DeleteFood from '../actions/delete-food';
import { EditIcon } from '../../../../../common/ui/icons';
import FoodUploadAttachmentButton from '../../attachment/upload-btn';
import FoodAttachment from '../../attachment/attachment';
import { markdown2Html } from '../../../../../common/markdown/markdown-provider';

interface IFoodItemProps {
    food: IFood;
    index: number;
}

const FoodItem = ({ food, index }: IFoodItemProps) => {

    const setFoodItemsEditPopup = useFoodStore(store => store.setFoodItemsEditPopup);

    const startEdit = () => {
        setFoodItemsEditPopup(food);
        sendCloseActionsEvent();
    };

    return (
        <Fragment key={ food.id }>
            <tr>
                <td className="border px-4 py-2 text-center bg-ray-100">{ index + 1 }</td>

                <td className="border px-4 py-2 bg-sky-50">
                    <button type="button"
                            title="Edit Food"
                            className="text-left w-full"
                            onClick={ startEdit }>
                        { food.name }
                    </button>
                </td>
                <td className="border px-4 py-2">{ food.calories }</td>
                <td className="border px-4 py-2">{ food.protein }</td>
                <td className="border px-4 py-2">{ food.fat }</td>
                <td className="border px-4 py-2">{ food.carbohydrates }</td>
                <td className="border px-4 py-2 text-center food-actions">
                    <Actions classes="food__actions justify-center" gridSize={ 3 } mr="mr-0" ml="ml-0" pl="pl-0">
                        <DeleteFood
                            food={ food }
                        />

                        <button
                            title="Edit Food"
                            className="food__edit flex bg-slate-200 rounded mx-1 text-left justify-center items-center whitespace-nowrap action-btn"
                            onClick={ startEdit }
                            type="button">
                            <EditIcon color={ '#6e7781' } size={ 20 } />
                        </button>

                        <FoodUploadAttachmentButton food={ food } />
                    </Actions>
                </td>

            </tr>

            {
                food.description &&
                <tr>
                    <td
                        colSpan={ 7 }
                        dangerouslySetInnerHTML={{__html: markdown2Html(food.description ?? '') }}
                        className="text-left text-sm border px-4 py-2 bg-white text-slate-900 markdown-editor"
                    />
                </tr>
            }

            {
                food.attachments?.length > 0 &&
                <tr>
                    <td colSpan={ 7 }
                        className="px-4 py-2">
                        <div className="flex flex-wrap items-center">
                            {
                                food.attachments.map(attachment => {
                                    return (
                                        <FoodAttachment
                                            key={ attachment.id }
                                            attachment={ attachment }
                                        />
                                    )
                                })
                            }
                        </div>
                    </td>
                </tr>
            }
        </Fragment>
    )
};

export default FoodItem;