import Actions from '../../../../../common/ui/actions';
import { IFinanceCategory } from '../../../finance-interfaces';
import { useState } from 'react';
import { EditIcon } from '../../../../../common/ui/icons';
import DeleteCategory from '../actions/delete-category';
import EditCategory from '../actions/edit-category';

interface IFinanceCategoryProps {
    category: IFinanceCategory;
}

enum CategoryMode {
    Default = 0,
    Edit = 1,
}

const FinanceCategory = ({ category }: IFinanceCategoryProps) => {

    const [mode, setMode] = useState<CategoryMode>(CategoryMode.Default);

    const startEdit = () => {
        setMode(CategoryMode.Edit);
    };

    const stopEdit = () => {
        setMode(CategoryMode.Default);
    };

    return (
        <div className="border-b pb-2 mb-2">
            {
                mode === CategoryMode.Default &&
                (
                    <>
                        <div className="flex items-center">
                            <div
                                className="mr-4"
                                style={{
                                    width: '10px',
                                    height: '10px',
                                    backgroundColor: category.CategoryColor,
                                }}
                            />
                            <div>{ category.CategoryName }</div>

                            <Actions classes="finance-business__actions" mr="mr-0" gridSize={ 2 }>
                                <DeleteCategory category={ category } />

                                <button
                                    title="Edit Business"
                                    className="finance__edit-business flex bg-slate-200 rounded mx-1 text-left justify-center items-center whitespace-nowrap action-btn"
                                    onClick={ startEdit }
                                    type="button">
                                    <EditIcon color={ '#6e7781' } size={ 20 } />
                                </button>
                            </Actions>
                        </div>

                        {
                            category.CategoryDescription &&
                            <div className="ml-7 text-gray-500">{ category.CategoryDescription }</div>
                        }
                    </>
                )
            }

            {
                mode === CategoryMode.Edit &&
                <EditCategory
                    category={ category }
                    close={ stopEdit }
                />
            }
        </div>
    )
};

export default FinanceCategory;