import { Pagination } from '../../../../../common/domain/pagination';
import { useFinanceStore } from '../../../data/finance-store';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const ITEMS_PER_PAGE = 10;
const PAGING_RANGE = 10;

interface IPaginationButton {
    id: string;
    text: string;
    isActive: boolean;
    page: number;
}

const TransactionsPagination = () => {

    const transactionsPageNumber = useFinanceStore(store => store.transactionsPageNumber);
    const setTransactionsPageNumber = useFinanceStore(store => store.setTransactionsPageNumber);

    const financeTransactionsData = useFinanceStore(store => store.financeTransactionsData);
    const [paging, setPaging] = useState<Pagination|null>(null);

    const total = financeTransactionsData?.total ?? 0;

    const [buttons, setButtons] = useState<IPaginationButton[]>([]);

    useEffect(() => {
        const paging = new Pagination(transactionsPageNumber, ITEMS_PER_PAGE, total, PAGING_RANGE);

        const _buttons: IPaginationButton[] = [];
        for (let i = paging.rangeStart; i <= paging.rangeEnd; i++) {
            _buttons.push({
                id: uuidv4(),
                text: (i + 1).toString(),
                isActive: transactionsPageNumber === i,
                page: i,
            });
        }

        setPaging(paging);
        setButtons(_buttons);
    }, [
        transactionsPageNumber,
        total,
    ]);

    const goToFirstPage = () => {
        setTransactionsPageNumber(0);
    };

    const prevPage = () => {
        if(!paging) return;
        setTransactionsPageNumber(paging.prevPage);
    };

    const nextPage = () => {
        if(!paging) return;
        setTransactionsPageNumber(paging.nextPage);
    };

    const goToLastPage = () => {
        if(!paging) return;
        setTransactionsPageNumber(paging.pagesNumber - 1);
    };

    const goToPage = (page: number) => {
        setTransactionsPageNumber(page);
    };

    return (
        <>
            {
                paging && paging.pagesNumber > 1 &&
                <div className="flex flex-col my-4">

                    <div className="flex flex-wrap">
                        <button
                            onClick={ goToFirstPage }
                            className={ `mb-4 text-sm px-3 py-1 border rounded mr-2 ${ transactionsPageNumber === 0 ? 'text-gray-300' : '' }` }
                            disabled={ transactionsPageNumber === 0 }
                            type="button">
                            First
                        </button>

                        <button
                            onClick={ prevPage }
                            className={ `mb-4 text-sm px-3 py-1 border rounded mr-2 ${ transactionsPageNumber === 0 ? 'text-gray-300' : '' }` }
                            disabled={ transactionsPageNumber === 0 }
                            type="button">
                            «
                        </button>

                        {
                            buttons.map(button => {
                                return (
                                    <button
                                        onClick={ () => {
                                            goToPage(button.page);
                                        }}
                                        key={ button.id }
                                        className={ `mb-4 text-sm px-3 py-1 border rounded mr-2 ${ button.isActive ? 'text-sky-500' : '' }` }
                                        type="button">
                                        { button.text }
                                    </button>
                                )
                            })
                        }

                        <button
                            onClick={ nextPage }
                            className={ `mb-4 text-sm px-3 py-1 border rounded mr-2 ${ transactionsPageNumber === total - 1 ? 'text-gray-300' : '' }` }
                            disabled={ transactionsPageNumber === total - 1 }
                            type="button">
                            »
                        </button>

                        <button
                            onClick={ goToLastPage }
                            className={ `mb-4 text-sm px-3 py-1 border rounded mr-2 ${ transactionsPageNumber === total - 1 ? 'text-gray-300' : '' }` }
                            disabled={ transactionsPageNumber === total - 1 }
                            type="button">
                            Last
                        </button>
                    </div>

                    <div className="text-sm">
                        Page { paging.currentPage + 1 } of { paging.pagesNumber } <sub>({ paging.start + 1 } - { paging.end } of { paging.itemsNumber })</sub>
                    </div>

                </div>
            }
        </>
    )
};

export default TransactionsPagination;