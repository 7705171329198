import { useFoodStore } from '../../data/food-store';

export enum FoodCategoriesTab {
    Default = 0,
    AddNew = 1,
}

const FoodCategoriesTabsNavigation = () => {

    const foodCategoriesTab = useFoodStore(store => store.foodCategoriesTab);
    const setFoodCategoriesTab = useFoodStore(store => store.setFoodCategoriesTab);

    const openListTab = () => {
        setFoodCategoriesTab(FoodCategoriesTab.Default);
    };

    const openAddNewTab = () => {
        setFoodCategoriesTab(FoodCategoriesTab.AddNew);
    };

    return (
        <nav className="flex border-b-8 mb-8 border-yellow-700 rounded-r-md">
            <button
                onClick={ openListTab }
                className={ `border-t border-l border-r px-10 py-2 mr-2 rounded-t-md border-yellow-700 ${ foodCategoriesTab === FoodCategoriesTab.Default ? 'bg-yellow-700 text-white' : '' }` }
                type="button">
                List
            </button>

            <button
                onClick={ openAddNewTab }
                className={ `border-t border-l border-r px-6 py-2 rounded-t-md border-yellow-700 ${ foodCategoriesTab === FoodCategoriesTab.AddNew ? 'bg-yellow-700 text-white' : '' }` }
                type="button">
                Add New
            </button>
        </nav>
    )
};

export default FoodCategoriesTabsNavigation;