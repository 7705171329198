import { create } from 'zustand';
import {
    IFinanceBusinessGeneralResponse,
    IFinanceCategoriesResponse, IFinanceTransactionItem,
    IFinanceTransactionsResponse
} from '../finance-interfaces';
import { BusinessesTab } from '../ui/businesses/tabs-navigation';
import { CategoriesTab } from '../ui/categories/tabs-navigation';
import { TransactionsTab } from '../ui/transactions/tabs-navigation';
import { getTransactionsMonthFromStorage, getTransactionsYearFromStorage } from '../domain/finance-storage';

interface FinanceState {

    // -------- Businesses -----------------------
    businessSearchTerm: string;
    setBusinessSearchTerm: (_businessSearchTerm: string) => void;

    financeBusinessData: IFinanceBusinessGeneralResponse|null;
    setFinanceBusinessData: (_financeBusinessData: IFinanceBusinessGeneralResponse|null) => void;

    businessesPageNumber: number;
    setBusinessesPageNumber: (_businessesPageNumber: number) => void;

    businessesTab: BusinessesTab;
    setBusinessesTab: (_businessesTab: BusinessesTab) => void;

    // -------- Categories -----------------------

    financeCategoriesData: IFinanceCategoriesResponse|null;
    setFinanceCategoriesData: (_financeBusinessData: IFinanceCategoriesResponse|null) => void;

    categoriesTab: CategoriesTab;
    setCategoriesTab: (_categoriesTab: CategoriesTab) => void;

    // -------- Transactions -----------------------

    financeTransactionsData: IFinanceTransactionsResponse|null;
    setFinanceTransactionsData: (_financeTransactionsData: IFinanceTransactionsResponse|null) => void;

    transactionsTab: TransactionsTab;
    setTransactionsTab: (_transactionsTab: TransactionsTab) => void;

    transactionsPageNumber: number;
    setTransactionsPageNumber: (_transactionsPageNumber: number) => void;

    transactionsSelectedYear: number;
    setTransactionsSelectedYear: (_transactionsSelectedYear: number) => void;

    transactionsSelectedMonth: number;
    setTransactionsSelectedMonth: (_transactionsSelectedMonth: number) => void;

    transactionsSelectedCategoryID: number;
    setTransactionsSelectedCategoryID: (_transactionsSelectedCategoryID: number) => void;

    transactionEditPopup: IFinanceTransactionItem|null;
    setTransactionEditPopup: (_transactionEditPopup: IFinanceTransactionItem|null) => void;

    transactionsSearchTerm: string;
    setTransactionSearchTerm: (_transactionsSearchTerm: string) => void;
}

export const useFinanceStore = create<FinanceState>()((set) => ({

    // -------- Businesses -----------------------

    businessSearchTerm: '',
    setBusinessSearchTerm: (_businessSearchTerm: string) => {
        return set((state: FinanceState) => {
            return {
                ...state,
                businessSearchTerm: _businessSearchTerm,
            };
        });
    },

    financeBusinessData: null,
    setFinanceBusinessData: (_financeBusinessData: IFinanceBusinessGeneralResponse|null) => {
        return set((state: FinanceState) => {
            return {
                ...state,
                financeBusinessData: _financeBusinessData,
            };
        });
    },

    businessesPageNumber: 0,
    setBusinessesPageNumber: (_businessesPageNumber: number) => {
        return set((state: FinanceState) => {
            return {
                ...state,
                businessesPageNumber: _businessesPageNumber,
            };
        });
    },

    businessesTab: BusinessesTab.Default,
    setBusinessesTab: (_businessesTab: BusinessesTab) => {
        return set((state: FinanceState) => {
            return {
                ...state,
                businessesTab: _businessesTab,
            };
        });
    },

    // -------- Categories -----------------------

    financeCategoriesData: null,
    setFinanceCategoriesData: (_financeCategoriesData: IFinanceCategoriesResponse|null) => {
        return set((state: FinanceState) => {
            return {
                ...state,
                financeCategoriesData: _financeCategoriesData,
            };
        });
    },

    categoriesTab: CategoriesTab.Default,
    setCategoriesTab: (_categoriesTab: CategoriesTab) => {
        return set((state: FinanceState) => {
            return {
                ...state,
                categoriesTab: _categoriesTab,
            };
        });
    },

    // -------- Transactions -----------------------

    financeTransactionsData: null,
    setFinanceTransactionsData: (_financeTransactionsData: IFinanceTransactionsResponse|null) => {
        return set((state: FinanceState) => {
            return {
                ...state,
                financeTransactionsData: _financeTransactionsData,
            };
        });
    },

    transactionsTab: TransactionsTab.Default,
    setTransactionsTab: (_transactionsTab: TransactionsTab) => {
        return set((state: FinanceState) => {
            return {
                ...state,
                transactionsTab: _transactionsTab,
            };
        });
    },

    transactionsSearchTerm: '',
    setTransactionSearchTerm: (_transactionsSearchTerm: string)  => {
        return set((state: FinanceState) => {
            return {
                ...state,
                transactionsSearchTerm: _transactionsSearchTerm,
            };
        });
    },

    transactionsPageNumber: 0,
    setTransactionsPageNumber: (_transactionsPageNumber: number) => {
        return set((state: FinanceState) => {
            return {
                ...state,
                transactionsPageNumber: _transactionsPageNumber,
            };
        });
    },

    transactionsSelectedYear: getTransactionsYearFromStorage(),
    setTransactionsSelectedYear: (_transactionsSelectedYear: number) => {
        return set((state: FinanceState) => {
            return {
                ...state,
                transactionsSelectedYear: _transactionsSelectedYear,
            };
        });
    },

    // Months are 0-indexed in JavaScript Date.
    transactionsSelectedMonth: getTransactionsMonthFromStorage(),
    setTransactionsSelectedMonth: (_transactionsSelectedMonth: number) => {
        return set((state: FinanceState) => {
            return {
                ...state,
                transactionsSelectedMonth: _transactionsSelectedMonth,
            };
        });
    },

    transactionsSelectedCategoryID: -1,
    setTransactionsSelectedCategoryID: (_transactionsSelectedCategoryID: number) => {
        return set((state: FinanceState) => {
            return {
                ...state,
                transactionsSelectedCategoryID: _transactionsSelectedCategoryID,
            };
        });
    },

    transactionEditPopup: null,
    setTransactionEditPopup: (_transactionEditPopup: IFinanceTransactionItem|null) => {
        return set((state: FinanceState) => {
            return {
                ...state,
                transactionEditPopup: _transactionEditPopup,
            };
        });
    },

}))