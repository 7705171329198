import { useFoodStore } from '../../data/food-store';

export enum FoodItemsTab {
    Default = 0,
    AddNew = 1,
}

const FoodItemsTabsNavigation = () => {

    const foodItemsTab = useFoodStore(store => store.foodItemsTab);
    const setFoodItemsTab = useFoodStore(store => store.setFoodItemsTab);

    const openListTab = () => {
        setFoodItemsTab(FoodItemsTab.Default);
    };

    const openAddNewTab = () => {
        setFoodItemsTab(FoodItemsTab.AddNew);
    };

    return (
        <nav className="flex border-b-8 mb-4 border-yellow-700 rounded-r-md">
            <button
                onClick={ openListTab }
                className={ `border-t border-l border-r px-10 py-2 mr-2 rounded-t-md border-yellow-700 ${ foodItemsTab === FoodItemsTab.Default ? 'bg-yellow-700 text-white' : '' }` }
                type="button">
                List
            </button>

            <button
                onClick={ openAddNewTab }
                className={ `border-t border-l border-r px-6 py-2 rounded-t-md border-yellow-700 ${ foodItemsTab === FoodItemsTab.AddNew ? 'bg-yellow-700 text-white' : '' }` }
                type="button">
                Add New
            </button>
        </nav>
    )
};

export default FoodItemsTabsNavigation;