import { ChangeEvent } from 'react';
import { IFoodCategory } from '../../food-interfaces';

interface IFoodCategoryDropDown {
    categoryId: number;
    onCategoryIdChange: (_categoryId: number) => void;
    categories: IFoodCategory[];
}

const FoodCategoryDropDown = ({
      categoryId,
      onCategoryIdChange,
      categories,
  }: IFoodCategoryDropDown) => {

    const onChange = (evt: ChangeEvent<HTMLSelectElement>) => {
        const value = Number(evt.target.value) || 0;
        onCategoryIdChange(value);
    };

    return (
        <select
            className="rounded bg-white border px-4 py-2"
            value={ categoryId }
            onChange={ onChange }>
            <option value={ -1 }>Select Category</option>
            {
                categories.map(category => {
                    return (
                        <option
                            key={ category.id }
                            value={ category.id }>{ category.name }</option>
                    )
                })
            }
        </select>
    )
};

export default FoodCategoryDropDown;