import { ChangeEvent } from 'react';
import { getDaysOptions } from '../../../../../../common/domain/date-time-provider';

interface ITransactionActionsDaysDropDown {
    transactionsSelectedDay: number;
    setTransactionsSelectedDay: (_transactionsSelectedDay: number) => void;
}

const TransactionActionsDaysDropDown = ({
      transactionsSelectedDay,
      setTransactionsSelectedDay,
  }: ITransactionActionsDaysDropDown) => {

    const onChange = (evt: ChangeEvent<HTMLSelectElement>) => {
        const value = Number(evt.target.value) || 0;
        setTransactionsSelectedDay(value);
    };

    return (
        <select
            className="rounded bg-white border px-4 py-2"
            value={ transactionsSelectedDay }
            onChange={ onChange }>
            { getDaysOptions() }
        </select>
    )
};

export default TransactionActionsDaysDropDown;