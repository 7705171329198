import { useState, MouseEvent } from 'react';
import { IFoodAttachment } from '../../food-interfaces';
import { useFoodStore } from '../../data/food-store';
import { showToast } from '../../../../common/domain/toast-provider';
import { sendCloseActionsEvent } from '../../../../common/ui/actions';
import { deleteFoodAttachment } from '../../data/food-data-service';
import { DeleteIcon, PreloaderIcon } from '../../../../common/ui/icons';

interface IDeleteAttachmentButton {
    attachment: IFoodAttachment;
}

const DeleteAttachmentButton = ({ attachment }: IDeleteAttachmentButton) => {

    const [isLoading, setLoading] = useState(false);

    const setFoodItemsData = useFoodStore(state => state.setFoodItemsData);
    const foodItemsPageNumber = useFoodStore(store => store.foodItemsPageNumber);
    const foodItemsSelectedCategoryID = useFoodStore(store => store.foodItemsSelectedCategoryID);
    const foodItemsSearchTerm = useFoodStore(store => store.foodItemsSearchTerm);

    const deleteAttachmentHandler = async (evt: MouseEvent<HTMLButtonElement>) => {
        evt.stopPropagation();
        evt.preventDefault();

        setLoading(true);
        const response = await deleteFoodAttachment(
            attachment.id,
            foodItemsPageNumber,
            foodItemsSelectedCategoryID,
            foodItemsSearchTerm,
        );
        setLoading(false);

        if(!response?.isValid) {
            showToast('Delete attachment error.');
            return;
        }

        setFoodItemsData(response);
        sendCloseActionsEvent();
    };

    return (
        <button
            onClick={ deleteAttachmentHandler }
            type="button"
            title="Delete Attachment">

            {
                !isLoading &&
                <DeleteIcon size={ 20 } classes="pointer-events-none" />
            }

            {
                isLoading &&
                <PreloaderIcon size={ 20 } />
            }
        </button>
    )
};

export default DeleteAttachmentButton;