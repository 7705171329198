import { useFoodStore } from '../../../data/food-store';
import FoodItem from './food-item';

const FoodItemsList = () => {

    const foodItemsData = useFoodStore(store => store.foodItemsData);

    return (
        <div className="w-full overflow-auto">
            <table className="w-full border border-collapse mb-3">
                <thead>
                <tr>
                    <th className="border px-4 py-2 bg-yellow-50">&nbsp;</th>
                    <th className="border px-4 py-2 text-left bg-yellow-50 align-top">Название</th>
                    <th className="border px-4 py-2 text-left bg-yellow-50 align-top">Калории</th>
                    <th className="border px-4 py-2 text-left bg-yellow-50 align-top">Белки<br/> <span className="text-xs font-normal">граммы</span></th>
                    <th className="border px-4 py-2 text-left bg-yellow-50 align-top">Жиры<br/> <span className="text-xs font-normal">граммы</span></th>
                    <th className="border px-4 py-2 text-left bg-yellow-50 align-top">Углеводы<br/> <span className="text-xs font-normal">граммы</span></th>
                    <th className="border px-4 py-2 bg-yellow-50">&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                {
                    foodItemsData?.foods.map(
                        (food, index) => {

                            return (
                                <FoodItem
                                    key={ food.id }
                                    food={ food }
                                    index={ index }
                                />
                            );
                        })

                }
                </tbody>
            </table>
        </div>
    )
};

export default FoodItemsList;