import { IFinanceTransactionItem } from '../../../../finance-interfaces';
import { getTransactionSourceName } from '../../../../domain/finance-storage';

interface IUploadPreview {
    transactions: IFinanceTransactionItem[];
}

const UploadPreview = ({ transactions }: IUploadPreview) => {

    const sum = transactions.reduce((prev, curr) => prev + curr.TransactionAmount, 0);

    return (
        <table className="w-full border border-collapse mb-3">
            <thead>
            <tr>
                <th className="border px-4 py-2">&nbsp;</th>
                <th className="border px-4 py-2 text-left">Date</th>
                <th className="border px-4 py-2 text-left">Amount, ₪</th>
                <th className="border px-4 py-2 text-left">Business Name</th>
                <th className="border px-4 py-2 text-left">Payments</th>
                <th className="border px-4 py-2 text-left">Description</th>
                <th className="border px-4 py-2 text-left">Source</th>
                <th className="border px-4 py-2 text-left">Confirm Num</th>
            </tr>
            </thead>
            <tbody>
            {
                transactions.map(
                    (transaction, i) => {

                        return (
                            <tr key={ transaction.TransactionID }>
                                <td
                                    className="border px-4 py-2 text-center"
                                    style={{
                                        backgroundColor: transaction.CategoryColor,
                                    }}
                                >{ i+1 }</td>

                                <td className="border px-4 py-2">
                                    { transaction.TransactionDay }/{ transaction.TransactionMonth + 1 }/{ transaction.TransactionYear }
                                </td>

                                <td className="border px-4 py-2">{ transaction.TransactionAmount.toFixed(2) }</td>
                                <td className="border px-4 py-2 whitespace-pre">{ transaction.TransactionBusinessName }</td>
                                <td className="border px-4 py-2">
                                    {
                                        (transaction.TransactionPaymentsNumber > 0) &&
                                        <span>
                                                {transaction.TransactionPaymentNumber} from {transaction.TransactionPaymentsNumber}
                                            </span>
                                    }
                                </td>
                                <td className="border px-4 py-2">{ transaction.TransactionDescription}</td>
                                <td className="border px-4 py-2">{ getTransactionSourceName(transaction.TransactionSource) }</td>
                                <td className="border px-4 py-2">{ transaction.TransactionConfirmationNumber }</td>
                            </tr>
                        );
                    })

            }

            <tr>
                <td colSpan={ 2 }></td>
                <td className="border px-4 py-2 text-left bg-yellow-300">
                    { sum.toLocaleString() }
                </td>
                <td colSpan={ 5 }></td>
            </tr>
            </tbody>
        </table>
    )
};

export default UploadPreview;