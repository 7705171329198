import { ExternalLink } from '../../../../../common/ui/icons';
import { ITodo } from '../../../planner-interfaces';

interface IOpenTodoLink {
    todo: ITodo;
}

const OpenTodoLink = ({ todo }: IOpenTodoLink) => {
    return (
        <a
            href={ `/planner/${ todo.project_id }#todo-${ todo.todo_id }` }
            className="todo__open-link flex bg-indigo-100 rounded mx-1 justify-center items-center action-btn"
            target="_blank"
            rel="noreferrer"
            title="Go to task">
            <ExternalLink size={ 15 } />
        </a>
    )
};

export default OpenTodoLink;