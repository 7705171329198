import Actions from '../../../../../common/ui/actions';
import { useState } from 'react';
import { EditIcon } from '../../../../../common/ui/icons';
import DeleteFoodCategory from '../actions/delete-food-category';
import EditFoodCategory from '../actions/edit-food-category';
import { IFoodCategory } from '../../../food-interfaces';

interface IFoodCategoryProps {
    category: IFoodCategory;
    index: number;
}

enum FoodCategoryMode {
    Default = 0,
    Edit = 1,
}

const FoodCategory = ({ category, index }: IFoodCategoryProps) => {

    const [mode, setMode] = useState<FoodCategoryMode>(FoodCategoryMode.Default);

    const startEdit = () => {
        setMode(FoodCategoryMode.Edit);
    };

    const stopEdit = () => {
        setMode(FoodCategoryMode.Default);
    };

    return (
        <div className="border-b pb-2 mb-2">
            {
                mode === FoodCategoryMode.Default &&
                (
                    <div className="flex items-start">
                        <div className="mr-4">{ index + 1 }.</div>

                        <div className="flex flex-col w-full">
                            <div className="flex space-between">

                                <button
                                    className="w-full text-left"
                                    onClick={ startEdit }
                                    type="button">{ category.name }</button>

                                <Actions classes="finance-business__actions" mr="mr-0" gridSize={ 2 }>
                                    <DeleteFoodCategory category={ category } />

                                    <button
                                        title="Edit Food Category"
                                        className="food__edit-category flex bg-slate-200 rounded mx-1 text-left justify-center items-center whitespace-nowrap action-btn"
                                        onClick={ startEdit }
                                        type="button">
                                        <EditIcon color={ '#6e7781' } size={ 20 } />
                                    </button>
                                </Actions>
                            </div>

                            {
                                category.description &&
                                <div className="text-gray-500 text-xs">{ category.description }</div>
                            }
                        </div>
                    </div>
                )
            }

            {
                mode === FoodCategoryMode.Edit &&
                <EditFoodCategory
                    category={ category }
                    close={ stopEdit }
                />
            }
        </div>
    )
};

export default FoodCategory;