import { useFinanceStore } from '../../data/finance-store';
import { useEffect } from 'react';
import { getFinanceCategoriesData } from '../../data/finance-data-service';
import { showToast } from '../../../../common/domain/toast-provider';
import CategoriesTabsNavigation, { CategoriesTab } from './tabs-navigation';
import CategoriesList from './list/categories-list';
import AddNewCategory from './actions/add-new-category';

const CategoriesHome = () => {

    const financeCategoriesData = useFinanceStore(store => store.financeCategoriesData);
    const setFinanceCategoriesData = useFinanceStore(store => store.setFinanceCategoriesData);
    const categoriesTab = useFinanceStore(store => store.categoriesTab);

    useEffect(() => {
        (async () => {
            const response = await getFinanceCategoriesData();

            if(!response) {
                showToast('Get categories error.');
                return;
            }

            setFinanceCategoriesData(response);
        })();
    }, [setFinanceCategoriesData]);

    return (
        <div>
            <h1 className="flex items-center text-xl xl:text-3xl mb-6">
                Categories
            </h1>

            <CategoriesTabsNavigation />

            {
                categoriesTab === CategoriesTab.Default &&
                financeCategoriesData?.categories?.length > 0 &&
                <>
                    <CategoriesList />
                </>

            }

            {
                categoriesTab === CategoriesTab.AddNew &&
                <AddNewCategory />
            }

        </div>
    )
};

export default CategoriesHome;