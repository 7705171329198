/**
 * Рассчитайте базовый обмен веществ (BMR)
 * BMR — это количество калорий, которое организм тратит в покое
 * для поддержания основных функций, таких как дыхание и кровообращение.
 * Формула Миффлина-Сан Жеора
 */
import { IFoodComposition, IFoodLog, IFoodLogGroup } from '../food-interfaces';

export const getBMR = (
    weightInKg: number,
    heightInCm: number,
    ageInYears: number,
    isFemale: boolean
) => {
    return 10 * weightInKg + 6.25 * heightInCm - 5 * ageInYears + (isFemale ? -161 : 5);
};

export enum PhysicalActivity {
    // Минимальная активность (сидячий образ жизни, мало физической активности)
    Minimal = 0,

    // Низкая активность (легкие тренировки или прогулки 1–3 раза в неделю)
    Low = 1,

    // Умеренная активность (тренировки средней интенсивности 3–5 раз в неделю)
    Moderate = 2,

    // Высокая активность (активные тренировки 6–7 раз в неделю)
    High = 3,

    // Очень высокая активность (интенсивные тренировки или физическая работа)
    VeryHigh = 4,
}

const PhysicalActivityMultiplier = {
    [PhysicalActivity.Minimal]: 1.2,
    [PhysicalActivity.Low]: 1.375,
    [PhysicalActivity.Moderate]: 1.55,
    [PhysicalActivity.High]: 1.725,
    [PhysicalActivity.VeryHigh]: 1.9,
};

/**
 * БЖУ — Белки, Жиры, Углеводы
 * PFC — Proteins, Fats, Carbohydrates
 *
 * Также иногда используется аббревиатура Macros
 * (от слова macronutrients, то есть макронутриенты)
 *
 * Обычно макронутриенты распределяются следующим образом:
 * -------------------------------------------------------
 *     Белки: 10–35% от суточной калорийности ---> 20%
 *     Жиры: 20–35% от суточной калорийности ---> 30%
 *     Углеводы: 45–65% от суточной калорийности ---> 50%
 */
export const getPFC = (calories: number) => {
    const proteins = calories * 0.2;
    const fats = calories * 0.3;
    const carbohydrates = calories * 0.5;

    return { proteins, fats, carbohydrates };
};

export const getMaintenanceDailyCalories = (
    weightInKg: number,
    heightInCm: number,
    ageInYears: number,
    isFemale: boolean,
    physicalActivity: PhysicalActivity
) : number => {
    const bmr = getBMR(weightInKg, heightInCm, ageInYears, isFemale);

    // После расчета BMR, его нужно умножить на
    // коэффициент физической активности, чтобы
    // получить общее количество калорий,
    // необходимое для поддержания текущего веса
    const activityMultiplier = PhysicalActivityMultiplier[physicalActivity];

    return Math.round(bmr * activityMultiplier);
};

/**
 * Body Mass Index (BMI)
 * ИМТ помогает определить,
 * в каком диапазоне находится вес,
 * в зависимости от роста.
 * Норма ИМТ для большинства взрослых — от 18.5 до 24.9.
 */
export const getBMI = (weightInKg: number, heightInCm: number) => {
    const heightInM = heightInCm/100;
    return Math.round(weightInKg / (heightInM ** 2));
};

/**
 * Calculates the standard BMI weight range (normal weight range) for a given height.
 */
export const getStandardBMIWeightRange = (heightInCm: number): { minWeightInKg: number; maxWeightInKg: number } => {
    const heightInMeters = heightInCm / 100;
    const minWeight = 18.5 * (heightInMeters ** 2);
    const maxWeight = 24.9 * (heightInMeters ** 2);

    return {
        minWeightInKg: Math.round(minWeight * 10) / 10, // rounding to one decimal place
        maxWeightInKg: Math.round(maxWeight * 10) / 10,
    };
};


/**
 * Calculates daily calorie needed to reach
 * a target weight over a specific time period in days.
 */
export const getDailyCaloriesForTargetWeight = (
    currentWeightInKg: number,
    targetWeightInKg: number,
    days: number,
    heightInCm: number,
    ageInYears: number,
    isFemale: boolean,
    physicalActivity: PhysicalActivity
): number => {
    // Calculate current maintenance calories based on current weight
    const currentMaintenanceCalories = getMaintenanceDailyCalories(
        currentWeightInKg,
        heightInCm,
        ageInYears,
        isFemale,
        physicalActivity
    );

    // Calculate target maintenance calories based on target weight
    const targetMaintenanceCalories = getMaintenanceDailyCalories(
        targetWeightInKg,
        heightInCm,
        ageInYears,
        isFemale,
        physicalActivity
    );

    const totalCaloricChange = targetMaintenanceCalories - currentMaintenanceCalories;
    const dailyCaloricAdjustment = totalCaloricChange / days;

    return Math.round(currentMaintenanceCalories + dailyCaloricAdjustment);
};

/**
 * Calculates daily calorie needed to reach
 * a target weight over a specific time period in days.
 */
export const getDailyCaloriesToBMIWeight = (
    currentWeightInKg: number,
    days: number,
    heightInCm: number,
    ageInYears: number,
    isFemale: boolean,
    physicalActivity: PhysicalActivity
): number => {
    // Calculate current maintenance calories based on current weight
    const currentMaintenanceCalories = getMaintenanceDailyCalories(
        currentWeightInKg,
        heightInCm,
        ageInYears,
        isFemale,
        physicalActivity
    );

    const bmiRange = getStandardBMIWeightRange(heightInCm);
    const targetWeightInKg = (bmiRange.maxWeightInKg - bmiRange.minWeightInKg) / 2;

    // Calculate target maintenance calories based on target weight
    const targetMaintenanceCalories = getMaintenanceDailyCalories(
        targetWeightInKg,
        heightInCm,
        ageInYears,
        isFemale,
        physicalActivity
    );

    const totalCaloricChange = targetMaintenanceCalories - currentMaintenanceCalories;
    const dailyCaloricAdjustment = totalCaloricChange / days;

    return Math.round(currentMaintenanceCalories + dailyCaloricAdjustment);
};

export const groupFoodLogsByDate = (logs: IFoodLog[]): IFoodLogGroup[] => {

    const groupedLogsMap = logs.reduce((acc, log) => {

            if (!acc.has(log.date)) {
                acc.set(log.date, []);
            }

            acc.get(log.date)?.push(log);

            return acc;
        },
        new Map<number, IFoodLog[]>()
    );

    return Array.from(groupedLogsMap.entries()).map(([date, foodLogs]) => ({
        date,
        foodLogs,
    }));
};

export const getConsumedCalories = (foodLogs: IFoodLog[]) : IFoodComposition => {
    let calories = 0;
    let fat = 0;
    let protein = 0;
    let carbohydrates = 0;

    if(!foodLogs) return {
        calories,
        fat,
        protein,
        carbohydrates,
    };

    foodLogs.map(foodLog => {
        calories += (foodLog.calories || 0) * foodLog.quantity;
        fat += (foodLog.fat || 0) * foodLog.quantity;
        protein += (foodLog.protein || 0) * foodLog.quantity;
        carbohydrates += (foodLog.carbohydrates || 0) * foodLog.quantity;
    });

    return {
        calories,
        fat,
        protein,
        carbohydrates,
    };
};
