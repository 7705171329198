import { useState } from 'react';
import { showToast } from '../../../../../common/domain/toast-provider';
import { DeleteIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { IFinanceBusiness } from '../../../finance-interfaces';
import { deleteBusiness } from '../../../data/finance-data-service';
import { useFinanceStore } from '../../../data/finance-store';

interface IDeleteBusiness {
    business: IFinanceBusiness;
}

const DeleteBusiness = ({ business }: IDeleteBusiness) => {

    const [isLoading, setLoading] = useState(false);

    const setFinanceBusinessData = useFinanceStore(store => store.setFinanceBusinessData);

    const deleteBusinessHandler = async () => {

        if(!confirm(`Are you sure you want to delete the business "${ business.BusinessName }"?`)) return;

        setLoading(true);
        const response = await deleteBusiness(business.BusinessID);
        setLoading(false);

        if(!response) {
            showToast('Delete business error.');
            return;
        }

        setFinanceBusinessData(response);
    };

    return (
        <>
            {
                isLoading &&
                <PreloaderIcon size={ 20 } color={ '#717985' } />
            }

            {
                !isLoading &&
                <button
                    title="Delete Business"
                    className="business__delete flex bg-pink-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
                    onClick={ deleteBusinessHandler }
                    type="button">
                    <DeleteIcon size={ 20 } color="#BE185D" />
                </button>
            }
        </>
    )
};

export default DeleteBusiness;