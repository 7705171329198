import CategoriesRadioFilter from './categories-radio-filter';
import { useFoodStore } from '../../../data/food-store';

const FoodCategoriesRadioFilters = () => {

    const foodItemsData = useFoodStore(store => store.foodItemsData);

    return (
        <div className="mt-2 mb-6 text-sm grid grid-cols-2 md:grid-cols-3 gap-2">

            <CategoriesRadioFilter
                categoryID={ -1 }
                categoryName="All"
                foodItemsCount={ 0 }
            />

            {
                foodItemsData?.categories.map(category => {
                    return (
                        <CategoriesRadioFilter
                            key={ category.id }
                            categoryID={ category.id }
                            categoryName={ category.name }
                            foodItemsCount={ category.food_items_count || 0 }
                        />
                    )
                })
            }
        </div>
    )
};

export default FoodCategoriesRadioFilters;