import { postJson } from '../../../common/data/common-data-service';
import { IOverviewResponseOld } from '../overview-interfaces';
import { CompletedFilterEnum } from '../ui/filters/completed-filter';
import { HasDateFilterEnum } from '../ui/filters/has-date-filter';
import { TodoType } from '../../planner/planner-interfaces';
import { RootFilterEnum } from '../ui/filters/root-filter';

export const getOverviewDataOld = async (
    page: number,
    sortBy: string,
    sortDirection: string,
    completedFilter: CompletedFilterEnum,
    hasDateFilter: HasDateFilterEnum,
    todoTypeFilter: Set<TodoType>,
    rootFilter: RootFilterEnum
) : Promise<IOverviewResponseOld> => {

    return await postJson(`/api/overview/get-data`, {
        page,
        sort: sortBy,
        sortDirection,
        completed: completedFilter,
        hasDate: hasDateFilter,
        todoTypeFilter: Array.from(todoTypeFilter),
        rootFilter,
    });
};