import { ChangeEvent } from 'react';
import { useFinanceStore } from '../../../../data/finance-store';
import { setTransactionsYearToStorage } from '../../../../domain/finance-storage';
import { getYearsOptions } from '../../../../../../common/domain/date-time-provider';

const YearsDropDown = () => {
    const transactionsSelectedYear = useFinanceStore(store => store.transactionsSelectedYear);
    const setTransactionsSelectedYear = useFinanceStore(store => store.setTransactionsSelectedYear);

    const onChange = (evt: ChangeEvent<HTMLSelectElement>) => {
        const value = Number(evt.target.value) || 0;
        setTransactionsSelectedYear(value);
        setTransactionsYearToStorage(value);
    };

    return (
        <select
            className="rounded bg-white border px-4 py-2 mr-4 mb-2 lg:mb-0"
            value={ transactionsSelectedYear }
            onChange={ onChange }>
            { getYearsOptions() }
        </select>
    )
};

export default YearsDropDown;