import { useFinanceStore } from '../../data/finance-store';

export enum TransactionsTab {
    Default = 0,
    AddNew = 1,
    UploadExcel = 2,
}

const TransactionsTabsNavigation = () => {

    const transactionsTab = useFinanceStore(store => store.transactionsTab);
    const setTransactionsTab = useFinanceStore(store => store.setTransactionsTab);

    const openListTab = () => {
        setTransactionsTab(TransactionsTab.Default);
    };

    const openAddNewTab = () => {
        setTransactionsTab(TransactionsTab.AddNew);
    };

    const openUploadTab = () => {
        setTransactionsTab(TransactionsTab.UploadExcel);
    };

    return (
        <nav className="flex flex-col md:flex-row border-b-8 mb-8 border-teal-700 rounded-r-md">
            <button
                onClick={ openListTab }
                className={ `border-t border-l border-r px-10 py-2 md:mr-2 rounded-t-md border-teal-700 ${ transactionsTab === TransactionsTab.Default ? 'bg-teal-700 text-white' : '' }` }
                type="button">
                List
            </button>

            <button
                onClick={ openAddNewTab }
                className={ `border-t border-l border-r px-6 py-2 md:mr-2 md:rounded-t-md border-teal-700 ${ transactionsTab === TransactionsTab.AddNew ? 'bg-teal-700 text-white' : '' }` }
                type="button">
                Add Transaction
            </button>

            <button
                onClick={ openUploadTab }
                className={ `border-t border-l border-r px-6 py-2 md:rounded-t-md border-teal-700 ${ transactionsTab === TransactionsTab.UploadExcel ? 'bg-teal-700 text-white' : '' }` }
                type="button">
                Upload Excel
            </button>
        </nav>
    )
};

export default TransactionsTabsNavigation;