import { Fragment, useEffect, useState } from 'react';
import { getOverviewDataOld } from '../data/overview-data-service';
import { showToast } from '../../../common/domain/toast-provider';
import Preloader from '../../../common/ui/preloader';
import { formatOverviewData } from '../domain/overview-provider';
import { getTodoTypeTextWithClass } from '../../planner/domain/todo-type-provider';
import TableDivider from './table/table-divider';
import TopMetadata from './table/top-metadata';
import Pagination from './paging/pagination';
import TableHeader from './table/table-header/table-header';
import { getDateData } from '../../planner/ui/todo/todo-date';
import CompletedFilter from './filters/completed-filter';
import HasDateFilter from './filters/has-date-filter';
import TodoTypeFilter from './filters/type-filter/todo-type-filter';
import RootFilter from './filters/root-filter';
import { markdown2Html } from '../../../common/markdown/markdown-provider';
import { useOverviewStore } from '../data/overview-store';

const OverviewTable = () => {

    const [loading, setLoading] = useState(false);

    const overviewData = useOverviewStore(state => state.overviewData);
    const setOverviewData = useOverviewStore(state => state.setOverviewData);

    const currentPage = useOverviewStore(state => state.currentPage);
    const sortBy = useOverviewStore(state => state.sortBy);
    const sortDirection = useOverviewStore(state => state.sortDirection);
    const completedFilter = useOverviewStore(state => state.completedFilter);
    const hasDateFilter = useOverviewStore(state => state.hasDateFilter);
    const todoTypeFilter = useOverviewStore(state => state.todoTypeFilter);
    const rootFilter = useOverviewStore(state => state.rootFilter);

    useEffect(() => {
        (async () => {

            setLoading(true);
            const response = await getOverviewDataOld(
                currentPage, sortBy, sortDirection,
                completedFilter, hasDateFilter,
                todoTypeFilter, rootFilter,
            );
            setLoading(false);

            if(!response) {
                showToast('Get overview-old data error.');
                return;
            }

            setOverviewData(await formatOverviewData(response, currentPage));
        })();
    }, [
        setOverviewData, currentPage, sortBy,
        sortDirection, completedFilter, hasDateFilter,
        todoTypeFilter, rootFilter,
    ]);

    return (
        <div>

            { loading && <Preloader/> }

            {
                !loading && overviewData && (
                    <>
                        <div className="flex items-center mb-6">
                            <CompletedFilter />
                            <RootFilter />
                            <HasDateFilter />
                            <TodoTypeFilter />

                            <TopMetadata />
                        </div>

                        <div className="grid grid-cols-8 shadow-xl py-4 px-8 rounded text-sm" style={{
                            gridTemplateColumns: 'min-content min-content auto min-content min-content min-content min-content min-content',
                        }}>
                            <TableHeader />

                            {
                                overviewData.todos?.map((todo, i) => {
                                    const dateData = getDateData(todo);

                                    return (
                                        <Fragment key={ todo.todo_id }>

                                            <div className="border-r border-slate-100 px-4 py-2 content-center">
                                                { todo.todo_id }
                                            </div>

                                            <div className="text-center border-r border-slate-100 px-4 py-2 content-center">
                                                { todo.completeness_percent.toFixed(2) } %
                                            </div>

                                            <a
                                                href={ `/planner/${ todo.project_id }#todo-${ todo.todo_id }` }
                                                className={ `border-r border-slate-100 px-4 py-2 content-center ${ todo.is_completed ? 'line-through' : 'underline' }` }
                                                dangerouslySetInnerHTML={{__html: markdown2Html(todo.todo_title) }}
                                            />

                                            <div className={ `border-r border-slate-100 px-4 py-2 text-center text-xs whitespace-nowrap content-center ${ dateData.colorClass }` }>
                                                { dateData?.dateString || '' }
                                            </div>

                                            <div className="border-r border-slate-100 px-4 py-2 text-center content-center">
                                                { todo.nested_count ?? 0 }
                                            </div>

                                            <div className="border-r border-slate-100 px-4 py-2 text-center content-center">
                                                { todo.nested_completed_count ?? 0 }
                                            </div>

                                            <div className="border-r border-slate-100 px-4 py-2 text-center content-center whitespace-nowrap">
                                                { getTodoTypeTextWithClass(todo.todo_type, true) }
                                            </div>

                                            <div className="text-center px-4 py-2 content-center">
                                                { todo.todo_tags || '' }
                                            </div>

                                            { (i !== overviewData.todos.length - 1) &&<TableDivider/> }
                                        </Fragment>
                                    )
                                })
                            }
                        </div>

                        <Pagination />
                    </>
                )
            }
        </div>
    )
};

export default OverviewTable;