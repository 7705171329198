import { ChangeEvent, KeyboardEvent as ReactKeyboardEvent, useEffect, useRef, useState } from 'react';
import { handleWiziwig } from '../../../../planner/domain/wiziwig-provider';
import { PreloaderIcon } from '../../../../../common/ui/icons';
import { showToast } from '../../../../../common/domain/toast-provider';
import { IFoodCategory } from '../../../food-interfaces';
import { useFoodStore } from '../../../data/food-store';
import { updateFoodCategory } from '../../../data/food-data-service';

interface IEditFoodCategory {
    category: IFoodCategory;
    close: () => void;
}

const EditFoodCategory = ({ category, close }: IEditFoodCategory) => {

    const nameRef = useRef<HTMLInputElement>(null);

    const setFoodCategoriesData = useFoodStore(store => store.setFoodCategoriesData);

    const [name, setName] = useState('');
    const [nameTouched, setNameTouched] = useState(false);

    const [description, setDescription] = useState('');
    const [isLoading, setLoading] = useState(false);

    const isNameValid = !nameTouched || name.trim().length > 0;

    useEffect(() => {
        nameRef?.current?.focus();
    }, []);

    useEffect(() => {
        setName(category.name);
        setDescription(category.description);
    }, [category]);

    const save = async () => {

        if(name.trim().length <= 0) {
            setNameTouched(true);
            return;
        }

        setLoading(true);

        const response = await updateFoodCategory(
            category.id,
            name,
            description,
        );

        setLoading(false);

        if(!response) {
            showToast('Update category error.');
            return;
        }

        setFoodCategoriesData(response);
        close();
    };

    const onNameChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setName((evt.target as HTMLInputElement).value);
        setNameTouched(true);
    };

    const onDescChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
        setDescription((evt.target as HTMLTextAreaElement).value);
    };

    const onNameKeyDown = async (evt: ReactKeyboardEvent<HTMLInputElement>) => {
        if(handleWiziwig(evt, nameRef, (newText) => {
            setName(newText);
            setNameTouched(true);
        })){
            return;
        }

        if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
            evt.preventDefault();
            await save();
            return;
        }

        if(evt.code === 'Enter') {
            await save();
            return;
        }

        if(evt.code === 'Escape') {
            close();
            return;
        }
    };

    const onDescKeyDown = async (evt: ReactKeyboardEvent<HTMLInputElement|HTMLTextAreaElement>) => {

        if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
            evt.preventDefault();
            await save();
            return;
        }

        if(evt.code === 'Enter') {
            await save();
            return;
        }

        if(evt.code === 'Escape') {
            close();
            return;
        }
    };

    return (
        <div>
            <label className="flex flex-col mb-4">
                <div className="font-bold text-slate-400 mb-2">Food Category Name</div>
                <input
                    ref={ nameRef }
                    value={ name }
                    onInput={ onNameChange }
                    onKeyDown={ onNameKeyDown }
                    className={ `border rounded px-4 py-2 ${ isNameValid ? 'outline-stone-200' : 'outline-red-200 border-red-200' }` }
                    type="text"
                />

                {
                    !isNameValid && <div className="text-red-700 text-xs mt-1">The name is required.</div>
                }
            </label>

            <label className="flex flex-col mb-4">
                <div className="font-bold text-slate-400 mb-2">Food Category Description</div>
                <textarea
                    value={ description }
                    onInput={ onDescChange }
                    onKeyDown={ onDescKeyDown }
                    className={ `border rounded px-4 py-2` }
                    rows={ 4 }
                />
            </label>

            <div className="flex items-center justify-end text-sm mt-4">

                {
                    isLoading &&
                    <PreloaderIcon size={ 24 } color={ '#717985' } />
                }

                {
                    !isLoading &&
                    <>
                        <button
                            className="bg-slate-500 text-slate-100 rounded px-6 py-2 mr-4"
                            onClick={ close }
                            type="button">Close</button>

                        <button
                            className="bg-teal-500 text-slate-100 rounded px-6 py-2"
                            onClick={ save }
                            type="button">Save</button>
                    </>
                }
            </div>

        </div>
    )
};

export default EditFoodCategory;