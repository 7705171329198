import { useFoodStore } from '../../../data/food-store';
import FoodCategory from './food-category';

const FoodCategoriesList = () => {
    const foodCategoriesData = useFoodStore(store => store.foodCategoriesData);

    return (
        <div>
            {
                foodCategoriesData?.categories?.map((category, index) => {
                    return (
                        <FoodCategory
                            key={ category.id }
                            category={ category }
                            index={ index }
                        />
                    )
                })
            }
        </div>
    )
};

export default FoodCategoriesList;