import { useAuthStatus } from '../auth/hooks/auth-status-hook';
import Preloader from '../../common/ui/preloader';
import MobileMenuBtn from '../../menu/ui/mobile-menu-btn';
import Menu from '../../menu/menu';
import { MouseEvent } from 'react';
import OverviewTable from './ui/overview-table';

const OverviewHomeOld = () => {

    const { loading } = useAuthStatus();

    const stopPropagation = (evt: MouseEvent<HTMLDivElement>) => {
        evt.stopPropagation();
    };

    return (
        <div className="text-slate-800">

            { loading && <Preloader/> }

            {
                !loading &&
                <div className="flex min-h-screen relative overflow-hidden">

                    <MobileMenuBtn />

                    <div
                        onClick={ stopPropagation }
                        className="mobile-menu-sidebar flex absolute z-[2000] right-0 translate-x-full transition-transform xl:static xl:translate-x-0">
                        <Menu showIcons={ true } hideSideBarToggle={ true } />
                    </div>

                    <div className="flex flex-col flex-1 leading-7 h-screen relative z-50 bg-white w-screen">
                        <div className="overflow-auto px-6 py-10">
                            <div className="xl:w-[1500px] max-w-full mx-auto">

                                <h1 className="text-xl xl:text-3xl border-b border-teal-400 pb-2 flex items-center mb-4">
                                    Overview
                                </h1>

                                <OverviewTable />

                            </div>
                        </div>
                    </div>

                </div>
            }
        </div>
    )
};

export default OverviewHomeOld;