import './css/index.pcss';
import './css/font.pcss';
import './css/menus.pcss';
import './css/editors.pcss';
import './css/drag.pcss';
import './css/active-todo.pcss';
import './css/calendar.pcss';
import './css/library.pcss';
import './css/print.pcss';
import './css/flash-cards.pcss';
import 'toastify-js/src/toastify.css';
import React, { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import Login from './projects/auth/ui/login';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import PlannerHome from './projects/planner/ui/planner-home';
import Preloader from './common/ui/preloader';
import DiagramHome from './projects/diagram/ui/diagram-home';
import OverviewHomeOld from './projects/overview-old/overview-home';
import TagsView from './projects/planner/ui/tags/tags-view';
import DescartesSquareHome from './projects/descartes-square/descartes-square-home';
import BlocksOverviewHome from './projects/blocks/blocks-overview-home';
import FlashcardsHome from './projects/flashcards/flashcards-home';
import StudyView from './projects/flashcards/ui/study/study-view';
import HabitTrackerSquareHome from './projects/habit-tracker/habit-tracker-home';
import LibraryHome from './projects/library/ui/library-home';
import PrintTodo from './projects/print/print-todo';
import LibraryReadView from './projects/library/ui/read/read-view';
import LibraryTagsView from './projects/library/ui/tags/tags-view/tags-view';
import OverviewHome from './projects/overview/overview-home';
import FinanceHome from './projects/finance/ui/finance-home';
import FoodHome from './projects/food/ui/food-home';
const WikiHome = lazy(() => import('./projects/wiki/ui/wiki-home'));
const CalendarHome = lazy(() => import('./projects/calendar/calendar-home'));
const AdminHome = lazy(() => import('./projects/admin/admin-home'));
const SearchView = lazy(() => import('./projects/planner/ui/search/search-view'));
const GalleryHome = lazy(() => import('./projects/gallery/ui/gallery-home'));
const FileSystemViewer = lazy(() => import('./projects/admin/ui/file-system-viewer/file-system-viewer'));

const init = () => {
    const $root = document.getElementById('app');
    if(!$root) return;

    if(document.location.hostname === 'localhost') {
        document.title += ' - DEV';
    }

    const root = createRoot($root);

    const router = createBrowserRouter([
        {
            path: '/',
            element: <Suspense fallback={<Preloader />}>
                <Login />
            </Suspense>,
        },
        {
            path: '/planner',
            element: <Suspense fallback={<Preloader />}>
                <PlannerHome />
            </Suspense>,
        },
        {
            path: '/planner/:project_id',
            element: <Suspense fallback={<Preloader />}>
                <PlannerHome />
            </Suspense>,
        },
        {
            path: '/planner/calendar',
            element: <Suspense fallback={<Preloader />}>
                <CalendarHome />
            </Suspense>,
        },
        {
            path: '/planner/search',
            element: <Suspense fallback={<Preloader />}>
                <SearchView />
            </Suspense>,
        },
        {
            path: '/planner/search/:search_term',
            element: <Suspense fallback={<Preloader />}>
                <SearchView />
            </Suspense>,
        },
        {
            path: '/planner/tags',
            element: <Suspense fallback={<Preloader />}>
                <TagsView />
            </Suspense>,
        },
        {
            path: '/diagram/:todo_id',
            element: <Suspense fallback={<Preloader />}>
                <DiagramHome />
            </Suspense>,
        },
        {
            path: '/descartes-square/:todo_id',
            element: <Suspense fallback={<Preloader />}>
                <DescartesSquareHome />
            </Suspense>,
        },
        {
            path: '/habit-tracker/:todo_id',
            element: <Suspense fallback={<Preloader />}>
                <HabitTrackerSquareHome />
            </Suspense>,
        },
        {
            path: '/wiki',
            element: <Suspense fallback={<Preloader />}>
                <WikiHome />
            </Suspense>,
        },
        {
            path: '/wiki/search',
            element: <Suspense fallback={<Preloader />}>
                <WikiHome />
            </Suspense>,
        },
        {
            path: '/overview-old',
            element: <Suspense fallback={<Preloader />}>
                <OverviewHomeOld />
            </Suspense>,
        },
        {
            path: '/overview',
            element: <Suspense fallback={<Preloader />}>
                <OverviewHome />
            </Suspense>,
        },
        {
            path: '/blocks',
            element: <Suspense fallback={<Preloader />}>
                <BlocksOverviewHome />
            </Suspense>,
        },
        {
            path: '/flashcards',
            element: <Suspense fallback={<Preloader />}>
                <FlashcardsHome />
            </Suspense>,
        },
        {
            path: '/flashcards/:selected_item_id',
            element: <Suspense fallback={<Preloader />}>
                <FlashcardsHome />
            </Suspense>,
        },
        {
            path: '/flashcards/study/:deck_id',
            element: <Suspense fallback={<Preloader />}>
                <StudyView />
            </Suspense>,
        },
        {
            path: '/flashcards/search',
            element: <Suspense fallback={<Preloader />}>
                <FlashcardsHome />
            </Suspense>,
        },
        {
            path: '/wiki/:file_or_folder_path',
            element: <Suspense fallback={<Preloader />}>
                <WikiHome />
            </Suspense>,
        },
        {
            path: '/wiki/:file_or_folder_path/*',
            element: <Suspense fallback={<Preloader />}>
                <WikiHome />
            </Suspense>,
        },
        {
            path: '/gallery',
            element: <Suspense fallback={<Preloader />}>
                <GalleryHome />
            </Suspense>,
        },
        {
            path: '/library',
            element: <Suspense fallback={<Preloader />}>
                <LibraryHome />
            </Suspense>,
        },
        {
            path: '/library/read/:book_file_id',
            element: <Suspense fallback={<Preloader />}>
                <LibraryReadView />
            </Suspense>,
        },
        {
            path: '/library/:path',
            element: <Suspense fallback={<Preloader />}>
                <LibraryHome />
            </Suspense>,
        },
        {
            path: '/library/tags',
            element: <Suspense fallback={<Preloader />}>
                <LibraryTagsView />
            </Suspense>,
        },
        {
            path: '/gallery/:album_id',
            element: <Suspense fallback={<Preloader />}>
                <GalleryHome />
            </Suspense>,
        },
        {
            path: '/print/todo/:todo_id',
            element: <Suspense fallback={<Preloader />}>
                <PrintTodo />
            </Suspense>,
        },
        {
            path: '/finance',
            element: <Suspense fallback={<Preloader />}>
                <FinanceHome />
            </Suspense>,
        },
        {
            path: '/finance/categories',
            element: <Suspense fallback={<Preloader />}>
                <FinanceHome />
            </Suspense>,
        },
        {
            path: '/finance/businesses',
            element: <Suspense fallback={<Preloader />}>
                <FinanceHome />
            </Suspense>,
        },
        {
            path: '/finance/transactions',
            element: <Suspense fallback={<Preloader />}>
                <FinanceHome />
            </Suspense>,
        },
        {
            path: '/food',
            element: <Suspense fallback={<Preloader />}>
                <FoodHome />
            </Suspense>,
        },
        {
            path: '/food/food-items',
            element: <Suspense fallback={<Preloader />}>
                <FoodHome />
            </Suspense>,
        },
        {
            path: '/food/categories',
            element: <Suspense fallback={<Preloader />}>
                <FoodHome />
            </Suspense>,
        },
        {
            path: '/admin/index',
            element: <Suspense fallback={<Preloader />}>
                <AdminHome />
            </Suspense>,
        },
        {
            path: '/admin/file-system-viewer',
            element: <Suspense fallback={<Preloader />}>
                <FileSystemViewer />
            </Suspense>,
        },
    ]);

    root.render(
        <React.StrictMode>
            <RouterProvider router={router} />
        </React.StrictMode>
    );
};

document.addEventListener('DOMContentLoaded', () => {
    init();
});