import { ChangeEvent, useEffect, useState } from 'react';
import { getFoodDropDownData } from '../../data/food-data-service';
import { IFoodCategory } from '../../food-interfaces';
import { showToast } from '../../../../common/domain/toast-provider';

interface IFoodDropDown {
    foodId: number;
    setFoodId: (_foodId: number) => void;
}

const FoodDropDown = ({ foodId, setFoodId }: IFoodDropDown) => {

    const [categories, setCategories] = useState<IFoodCategory[]>([]);

    useEffect(() => {
        (async () => {
            const response = await getFoodDropDownData();

            if(!response) {
                showToast('Get drop down data error.');
                return;
            }

            setCategories(response || []);
        })();
    }, []);

    const onChange = (evt: ChangeEvent<HTMLSelectElement>) => {
        const value = Number(evt.target.value) || 0;
        setFoodId(value);
    };

    return (
        <select
            className="rounded bg-white border px-4 py-2"
            onChange={ onChange }
            value={ foodId }>

            <option value={ 0 }>Select Food</option>

            {
                categories?.map(category => {
                    return (
                        <optgroup
                            key={ category.id }
                            label={ category.name }>
                            {
                                category?.foods?.map(food => {
                                    return (
                                        <option
                                            key={ food.id }
                                            value={ food.id }>{ food.name }</option>
                                    )
                                })
                            }
                        </optgroup>
                    )
                })
            }
        </select>
    )
};

export default FoodDropDown;