import { create } from 'zustand';
import { IOverviewData } from '../interfaces';

interface OverviewState {
    overviewData: IOverviewData|null;
    setOverviewData: (_overviewData: IOverviewData|null) => void;
}

export const useOverviewStore = create<OverviewState>()((set) => ({
    overviewData: null,
    setOverviewData: (_overviewData: IOverviewData|null) => {
        return set((state: OverviewState) => {
            return {
                ...state,
                overviewData: _overviewData,
            };
        });
    },
}))