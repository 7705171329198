import { useState } from 'react';
import { showToast } from '../../../../../common/domain/toast-provider';
import { DeleteIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { IFinanceCategory } from '../../../finance-interfaces';
import { useFinanceStore } from '../../../data/finance-store';
import { deleteCategory } from '../../../data/finance-data-service';

interface IDeleteCategory {
    category: IFinanceCategory;
}

const DeleteCategory = ({ category }: IDeleteCategory) => {

    const [isLoading, setLoading] = useState(false);

    const setFinanceCategoriesData = useFinanceStore(store => store.setFinanceCategoriesData);

    const deleteCategoryHandler = async () => {

        if(!confirm(`Are you sure you want to delete the category "${ category.CategoryName }"?`)) return;

        setLoading(true);
        const response = await deleteCategory(category.CategoryID);
        setLoading(false);

        if(!response) {
            showToast('Delete category error.');
            return;
        }

        setFinanceCategoriesData(response);
    };

    return (
        <>
            {
                isLoading &&
                <PreloaderIcon size={ 20 } color={ '#717985' } />
            }

            {
                !isLoading &&
                <button
                    title="Delete Category"
                    className="category__delete flex bg-pink-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
                    onClick={ deleteCategoryHandler }
                    type="button">
                    <DeleteIcon size={ 20 } color="#BE185D" />
                </button>
            }
        </>
    )
};

export default DeleteCategory;