import { ResetIcon } from '../../../../common/ui/icons';
import { removeTodoFromOverview } from '../../data/overview-data-service';
import { formatOverviewData } from '../../domain/overview-provider';
import { useOverviewStore } from '../../data/overview-store';
import { ITodo } from '../../../planner/planner-interfaces';

interface IRemoveTodoFromOverviewBtn {
    todo: ITodo;
}

const RemoveTodoFromOverviewBtn = ({ todo }: IRemoveTodoFromOverviewBtn) => {

    const setOverviewData = useOverviewStore(store => store.setOverviewData);

    const onClick = async () => {
        const response = await removeTodoFromOverview(todo.todo_id);
        setOverviewData(formatOverviewData(response));
    };

    return (
        <button
            title="Remove From Overview"
            className="flex bg-stone-200 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
            onClick={ onClick }
            type="button">
            <ResetIcon size={ 20 } />
        </button>
    )
};

export default RemoveTodoFromOverviewBtn;