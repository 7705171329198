import { IFoodAttachment } from '../../food-interfaces';
import { checkIsImage } from '../../../../common/domain/file-provider';
import DeleteAttachmentButton from './delete-btn';

/*enum AttachmentMode {
    DEFAULT = 0,
    EDIT = 1,
}*/

interface IFoodAttachmentProps {
    attachment: IFoodAttachment;
}

const FoodAttachment = ({ attachment }: IFoodAttachmentProps) => {

    // const [mode, setMode] = useState<AttachmentMode>(AttachmentMode.DEFAULT);

    const path = `/api/food/attachment/get?path=${ attachment.path }`;
    const isImage = checkIsImage(path);

   /* const startEditDescription = () => {
        setMode(AttachmentMode.EDIT);
    };

    const stopEditDescription = () => {
        setMode(AttachmentMode.DEFAULT);
    };*/

    return (
        <div className="flex flex-col mr-4 mb-4">
            {
                isImage &&
                <a
                    className="attachment__box flex flex-col"
                    href={ path }
                    rel="noreferrer"
                    target="_blank"
                    style={{
                        width: '150px',
                    }}>

                    <div className="relative flex">
                        <img
                            className="rounded mt-2 max-w-full"
                            src={ path }
                            alt={ attachment.description }
                        />

                        <div className="attachment__action hidden flex justify-center items-center mb-2 absolute right-[3px] bottom-0 bg-white rounded p-1">

                            <DeleteAttachmentButton
                                attachment={ attachment }
                            />

                            {/*<
                            <FoodAttachmentEditButton
                                startEditDescription={ startEditDescription }
                            />*/}
                        </div>
                    </div>

                    {
                        attachment.description &&
                        <div className="italic mt-1">{ attachment.description }</div>
                    }

                </a>
            }

            {
                !isImage &&
                <a className="attachment__box flex flex-col leading-6"
                   href={ path }
                   rel="noreferrer"
                   target="_blank">

                    <div className="flex items-center relative">
                        <div className="border rounded py-2 px-4 bg-white mr-4">{ attachment.path }</div>
                        <div className="attachment__action hidden">
                            <DeleteAttachmentButton
                                attachment={ attachment }
                            />

                            {/*
                            <FoodAttachmentEditButton
                                startEditDescription={ startEditDescription }
                            />*/}
                        </div>
                    </div>

                    {
                        attachment.description &&
                        <div className="italic mt-1">{ attachment.description }</div>
                    }
                </a>
            }

            {/*{
                mode === AttachmentMode.EDIT &&
                <FoodAttachmentEdit
                    attachment={ attachment }
                    stopEditDescription={ stopEditDescription }
                />
            }*/}
        </div>
    )
};

export default FoodAttachment;