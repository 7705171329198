import { IFinanceTransactionItem, TransactionSource } from '../finance-interfaces';
import { parse } from 'chrono-node';

export interface ExcelRow {
    [key: string]: string;
}

// --------- POSSIBLE FIELD NAMES IN EXCEL ----------------

const FIELD_DATE_VARIATIONS = new Set([
    'תאריך עסקה',
    'תאריך העסקה',
    'תאריך',
    'תאריך רכישה',
    'תאריך חיוב',
]);

const FIELD_BUSINESS_NAME_VARIATIONS = new Set([
    'שם בית העסק',
    'שם בית עסק',
]);

const FIELD_CONFIRMATION_NUM_VARIATIONS = new Set([
    'מספר שובר',
    'אסמכתא',
]);

const FIELD_AMOUNT_VARIATIONS = new Set([
    'חובה',
    'בחובה',
    'סכום החיוב',
    'זכות',
    'בזכות',
    'סכום חיוב ₪',
    'סכום חיוב',
]);

const FIELD_DESC_VARIATIONS = new Set([
    'תאור מורחב',
    'הערות',
    'תיאור',
    'פירוט נוסף',
]);

// ---------------------------------------------------------

const tryParseExcelCell = (transaction: IFinanceTransactionItem, key: string, value: string) : IFinanceTransactionItem => {

    key = key.trim();
    value = value.trim();

    if(value === '') return transaction;

    if(FIELD_BUSINESS_NAME_VARIATIONS.has(key)) {
        if(transaction.TransactionBusinessName === '') {
            transaction.TransactionBusinessName = value;
        }

        return transaction;
    }

    if(FIELD_DESC_VARIATIONS.has(key)) {
        const found = value.match(/\u05EA\u05E9\u05DC\u05D5\u05DD (\d+) \u05DE\u05EA\u05D5\u05DA (\d+)/);

        if (found && found.length >= 3) {
            transaction.TransactionPaymentNumber = Number(found[1]) || 0;
            transaction.TransactionPaymentsNumber = Number(found[2]) || 0;
        }
        else{
            if(value.length > transaction.TransactionDescription.length) {
                transaction.TransactionDescription = value;
            }
        }

        return transaction;
    }

    if(FIELD_CONFIRMATION_NUM_VARIATIONS.has(key)) {
        if(transaction.TransactionConfirmationNumber === '') {
            transaction.TransactionConfirmationNumber = value;
        }

        return transaction;
    }

    if(FIELD_AMOUNT_VARIATIONS.has(key)) {

        if(transaction.TransactionAmount === 0) {
            const formattedValue = Number(value.toString().replace(/[^0-9.-]/g, ''));
            if (!isNaN(formattedValue)) {
                transaction.TransactionAmount = Number(formattedValue) || 0;

                if(key.includes('זכות')) {
                    transaction.TransactionAmount = -1 * Math.abs(transaction.TransactionAmount);
                }
            }
        }

        return transaction;
    }

    if(FIELD_DATE_VARIATIONS.has(key)) {

        if(transaction.TransactionDay === 1) {
            const results = parse(value) ?? [];

            if(results.length > 0) {
                const parsed = results[0];
                const date = parsed.start?.date();
                if(date) {
                    transaction.TransactionDay = date.getDate();
                }
            }
        }

        return transaction;
    }

    return transaction;
};

export const getTransactions = (rows: ExcelRow[], sourceValue: TransactionSource, currentYear: number, currentMonth: number) : IFinanceTransactionItem[] => {

    const transactions: IFinanceTransactionItem[] = [];

    for(const row of rows) {

        let transaction: IFinanceTransactionItem = {
            TransactionID: 0,

            TransactionDay: 1,
            TransactionMonth: currentMonth,
            TransactionYear: currentYear,
            TransactionSource: sourceValue,

            TransactionBusinessName: '',
            TransactionDescription: '',
            TransactionConfirmationNumber: '',
            TransactionAmount: 0,

            TransactionPaymentsNumber: 0,
            TransactionPaymentNumber: 0,
        };

        for (const [key, value] of Object.entries(row)) {

            // replace newline with space
            let _key = key.replace(/\n/g, ' ');

            // replace multiple spaces with one
            _key = _key.replace(/\s+/g, ' ');

            transaction = tryParseExcelCell(transaction, _key, value.toString());
        }

        if(transaction.TransactionBusinessName !== '' ||
            transaction.TransactionAmount > 0 ||
            transaction.TransactionDescription !== '') {
            transactions.push(transaction);
        }
    }

    return transactions;
};