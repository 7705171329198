import { ChangeEvent } from 'react';
import { useFinanceStore } from '../../../../data/finance-store';
import { setTransactionsMonthToStorage } from '../../../../domain/finance-storage';
import { getMonthsOptions } from '../../../../../../common/domain/date-time-provider';

const MonthsDropDown = () => {
    const transactionsSelectedMonth = useFinanceStore(store => store.transactionsSelectedMonth);
    const setTransactionsSelectedMonth = useFinanceStore(store => store.setTransactionsSelectedMonth);

    /**
     * Months are 0-indexed in JavaScript Date.
     */
    const onChange = (evt: ChangeEvent<HTMLSelectElement>) => {
        const value = Number(evt.target.value) || 0;
        setTransactionsSelectedMonth(value);
        setTransactionsMonthToStorage(value);
    };

    return (
        <select
            className="rounded bg-white border px-4 py-2 mr-4 mb-2 lg:mb-0"
            value={ transactionsSelectedMonth }
            onChange={ onChange }>
            { getMonthsOptions() }
        </select>
    )
};

export default MonthsDropDown;