import TransactionSourceDropDown from '../drop-downs/transaction-source-drop-down';
import { ChangeEvent, useState } from 'react';
import { IFinanceTransactionItem, TransactionSource } from '../../../../finance-interfaces';
import TransactionActionsMonthsDropDown from '../drop-downs/months-drop-down';
import TransactionActionsYearsDropDown from '../drop-downs/years-drop-down';
import { PreloaderIcon, UploadIcon } from '../../../../../../common/ui/icons';
import { showToast } from '../../../../../../common/domain/toast-provider';
import { read, utils } from 'xlsx';
import { ExcelRow, getTransactions } from '../../../../domain/excel-provider';
import UploadPreview from './upload-preview';
import { insertBulkTransactions } from '../../../../data/finance-data-service';
import { useFinanceStore } from '../../../../data/finance-store';
import { TransactionsTab } from '../../tabs-navigation';
import { getTransactionsMonthFromStorage, getTransactionsYearFromStorage } from '../../../../domain/finance-storage';

const UploadExcel = () => {

    const setFinanceTransactionsData = useFinanceStore(store => store.setFinanceTransactionsData);
    const setTransactionsTab = useFinanceStore(store => store.setTransactionsTab);
    const transactionsPageNumber = useFinanceStore(store => store.transactionsPageNumber);
    const transactionsSelectedYear = useFinanceStore(store => store.transactionsSelectedYear);
    const transactionsSelectedMonth = useFinanceStore(store => store.transactionsSelectedMonth);
    const transactionsSelectedCategoryID = useFinanceStore(store => store.transactionsSelectedCategoryID);

    const [transactionSource, setTransactionSource] = useState<TransactionSource>(TransactionSource.BankLeumi);
    const [month, setMonth] = useState(getTransactionsMonthFromStorage());
    const [year, setYear] = useState(getTransactionsYearFromStorage());
    const [loading, setLoading] = useState(false);
    const [parsedTransactions, setParsedTransactions] = useState<IFinanceTransactionItem[]>([]);

    const onTransactionSourceChange = (_transactionSource: TransactionSource) => {
        setTransactionSource(_transactionSource);
    };

    const save = async () => {

        if(parsedTransactions?.length <= 0) {
            showToast('Upload error.');
            return;
        }

        setLoading(true);
        const response = await insertBulkTransactions(
            parsedTransactions,
            transactionsPageNumber,
            transactionsSelectedYear,
            transactionsSelectedMonth,
            transactionsSelectedCategoryID,
        );
        setLoading(false);

        if(!response?.isValid) {
            showToast('Upload error.');
        }
        else{
            showToast('Upload OK.', false);
            setFinanceTransactionsData(response);
            setTransactionsTab(TransactionsTab.Default);
        }
    };

    const uploadHandler = async (evt: ChangeEvent<HTMLInputElement>) => {
        if(!evt.target.files || evt.target.files.length <= 0){
            showToast('Upload error.');
            return;
        }

        const fileReader = new FileReader();

        fileReader.onload = (event) => {
            const arrayBuffer = event.target?.result;
            if (!arrayBuffer) {
                showToast('Upload error.');
                return;
            }

            const workbook = read(arrayBuffer, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const data = utils.sheet_to_json(sheet) as ExcelRow[];

            if(data.length <= 0) {
                showToast('Parse error.');
                return;
            }

            const parsed = getTransactions(data, transactionSource, year, month);
            if(parsed.length <= 0) {
                showToast('Parse error.');
                return;
            }

            setParsedTransactions(parsed);
        };

        const file = evt.target.files[0];
        fileReader.readAsArrayBuffer(file);
    };

    return (
        <div>
            <label className="flex flex-col mb-4">
                <div className="text-slate-400 mb-1">Transaction Source</div>
                <TransactionSourceDropDown
                    transactionSource={ transactionSource }
                    setTransactionsSource={ onTransactionSourceChange }
                />
            </label>

            <div className="grid grid-cols-2 gap-4 mb-4">

                <div className="flex flex-col">
                    <label className="text-sm text-gray-400 mb-1">Month</label>
                    <TransactionActionsMonthsDropDown
                        transactionsSelectedMonth={ month }
                        setTransactionsSelectedMonth={ setMonth }
                    />
                </div>

                <div className="flex flex-col">
                    <label className="text-sm text-gray-400 mb-1">Year</label>
                    <TransactionActionsYearsDropDown
                        transactionsSelectedYear={ year }
                        setTransactionsSelectedYear={ setYear }
                    />
                </div>
            </div>

            {
                loading &&
                <PreloaderIcon size={ 20 } color={ '#000' } />
            }

            {
                !loading &&
                <div className="mb-4">
                    <div className="text-sm text-gray-400 mb-2">Upload</div>
                    <label className="flex bg-slate-100 border border-slate-200 text-slate-800 rounded px-4 py-2 cursor-pointer">
                        <input
                            onChange={ uploadHandler }
                            className="hidden"
                            type="file"
                        />
                        <UploadIcon color={ '#366d8c' } classes="mr-2" />
                        <span>Upload Excel</span>
                    </label>
                </div>
            }

            {
                !loading && parsedTransactions?.length > 0 &&
                <>
                    <UploadPreview transactions={ parsedTransactions } />

                    <div className="flex justify-end">
                        <button
                            onClick={ save }
                            className="bg-teal-700 text-white px-10 py-3 rounded"
                            type="button">
                            Upload
                        </button>
                    </div>
                </>
            }
        </div>
    )
};

export default UploadExcel;