import TransactionsTabsNavigation, { TransactionsTab } from './tabs-navigation';
import { useFinanceStore } from '../../data/finance-store';
import { useEffect } from 'react';
import { showToast } from '../../../../common/domain/toast-provider';
import { getFinanceTransactionsData } from '../../data/finance-data-service';
import TransactionsList from './list/transactions-list';
import TopActionsAndData from './top-panel/top-actions-and-data';
import TransactionsPagination from './list/pagination';
import AddTransaction from './actions/add-transaction';
import EditTransactionPopup from './actions/edit-transaction';
import UploadExcel from './actions/upload/upload-excel';

const TransactionsHome = () => {

    const financeTransactionsData = useFinanceStore(store => store.financeTransactionsData);
    const setFinanceTransactionsData = useFinanceStore(store => store.setFinanceTransactionsData);
    const transactionsTab = useFinanceStore(store => store.transactionsTab);

    const transactionsPageNumber = useFinanceStore(store => store.transactionsPageNumber);
    const transactionsSelectedYear = useFinanceStore(store => store.transactionsSelectedYear);
    const transactionsSelectedMonth = useFinanceStore(store => store.transactionsSelectedMonth);
    const transactionsSelectedCategoryID = useFinanceStore(store => store.transactionsSelectedCategoryID);
    const transactionsSearchTerm = useFinanceStore(store => store.transactionsSearchTerm);

    useEffect(() => {
        (async () => {
            const response = await getFinanceTransactionsData(
                transactionsPageNumber,
                transactionsSelectedYear,
                transactionsSelectedMonth,
                transactionsSelectedCategoryID,
                transactionsSearchTerm,
            );

            if(!response) {
                showToast('Get transactions error.');
                return;
            }

            setFinanceTransactionsData(response);
        })();
    }, [
        setFinanceTransactionsData,
        transactionsPageNumber,
        transactionsSelectedCategoryID,
        transactionsSelectedMonth,
        transactionsSelectedYear,
        transactionsSearchTerm,
    ]);

    return (
        <div>
            <h1 className="flex items-center text-xl xl:text-3xl mb-6">
                Transactions
            </h1>

            <TransactionsTabsNavigation />

            {
                transactionsTab === TransactionsTab.Default &&
                <>
                    <TopActionsAndData />

                    {
                        financeTransactionsData?.transactions?.length > 0 &&
                        <>
                            <TransactionsList />
                            <TransactionsPagination />
                            <EditTransactionPopup />
                        </>
                    }
                </>
            }

            {
                transactionsTab === TransactionsTab.AddNew &&
                <AddTransaction />
            }

            {
                transactionsTab === TransactionsTab.UploadExcel &&
                <UploadExcel />
            }

        </div>
    )
};

export default TransactionsHome;