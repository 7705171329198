import Popup from '../../../../../common/ui/popup';
import { usePlannerStore } from '../../../data/planner-store';
import CodeMirror from '@uiw/react-codemirror';
import { oneDark } from '@codemirror/theme-one-dark';
import { markdown } from '@codemirror/lang-markdown';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import TodoSplitButtons from './todo_split-buttons';
import TodoSplitFields from './todo-split-fields';
import { ITodoSplitItem } from './interfaces';
import TodoSplitItem from './todo-split-item';
// @ts-ignore
import { parse } from 'chrono-node/ru';
import { splitTodo } from '../../../data/planner-data-service';
import { showToast } from '../../../../../common/domain/toast-provider';
import { combineProjectData } from '../../../domain/planner-provider';

const POPUP_HEIGHT = 600;

const TodoSplitPopup = () => {

    const splitPopupTodo = usePlannerStore(state => state.splitPopupTodo);
    const setSplitPopupTodo = usePlannerStore(state => state.setSplitPopupTodo);
    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);

    const [description, setDescription] = useState('');
    const [separator, setSeparator] = useState('\\n');
    const [dateFormat, setDateFormat] = useState('dd-MM-yyyy');
    const [items, setItems] = useState<ITodoSplitItem[]>([]);
    const [isLoading, setLoading] = useState(false);
    const [useMainTodoTitle, setUseMainTodoTitle] = useState(false);

    useEffect(() => {
        setDescription(splitPopupTodo?.todo_desc ?? '');
    }, [splitPopupTodo]);

    const onClose = () => {
        setSplitPopupTodo(null);
    };

    const onChange = useCallback((value: string) => {
        setDescription(value);
    }, []);

    const resetDescription = () => {
        setDescription(splitPopupTodo?.todo_desc ?? '');
    };

    const split = () => {
        const _separator = separator.replace(/\\n/g, '\n');
        const _items = description.trim().split(_separator);
        const result: ITodoSplitItem[] = [];

        for(const item of _items) {

            /**
             * Parse the dates from the text:
             * text - The exact text that was recognized as a date in the input string.
             * index - The position (character index) where the recognized date starts in the original input text.
             * start - Contains details about the parsed date, such as the year, month, day, hour, minute, etc. start represents the "start" date when dealing with ranges. You can call start.date() to get a JavaScript Date object for the parsed date.
             * end (optional) -  If the text represents a date range (like "from January 1 to January 5"), end will contain the parsed "end" date. If there’s no range, end will be null.
             */
            const results = parse(item) ?? [];

            if(results.length > 0) {
                const parsed = results[0];
                let content = item.substring(0, parsed.index) + item.substring(parsed.index + parsed.text.length);
                let title = '';
                let description = '';

                if(content.startsWith('-')) {
                    content = content.substring(1);
                }

                if(content.endsWith('.md')) {
                    content = content.replace(/\.md$/, '');
                }

                content = content.trim();

                const date = parsed.start?.date();
                title = content;

                if(useMainTodoTitle) {
                    title = splitPopupTodo.todo_title;
                    description = content;
                }
                else{
                    if(content === '') {
                        if(date) {
                            title = splitPopupTodo.todo_title;
                        }
                        else continue;
                    }
                }

                result.push({
                    title,
                    date: parsed.start.date(),
                    description,
                });
            }
            else{
                if(item.trim() === '') continue;

                result.push({
                    title: item,
                    date: null,
                    description: '',
                });
            }
        }

        setItems(result);
    };

    const save = async () => {
        setLoading(true);

        const response = await splitTodo(splitPopupTodo.todo_id, items);

        setLoading(false);

        if(!response) {
            showToast('Split todo error.');
            return;
        }

        setCombinedProject(combineProjectData(response));

        onClose();
    };

    const handleSeparatorChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setSeparator(evt.target.value);
    };

    const handleDateFormatChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setDateFormat(evt.target.value);
    };

    const onUseMainTodoTitleChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setUseMainTodoTitle(evt.target.checked);
    };

    return (
        <Popup onClose={ onClose } width={ 1000 } height={ POPUP_HEIGHT }>
            <div className="flex flex-col md:overflow-hidden rounded">
                <div className="flex">

                    <div className="w-full md:w-1/2 overflow-auto bg-black" style={{
                        height: `${ POPUP_HEIGHT - 60 }px`,
                    }}>
                        <CodeMirror
                            value={ description }
                            extensions={[ markdown() ]}
                            onChange={ onChange }
                            theme={ oneDark }
                            basicSetup={ true }
                        />
                    </div>

                    <div className="w-full md:w-1/2 h-full px-4 py-3">
                        <h1 className="pb-3 text-xl">Split Todo</h1>

                        <TodoSplitFields
                            separator={ separator }
                            onSeparatorChange={ handleSeparatorChange }
                            dateFormat={ dateFormat }
                            onDateFormatChange={ handleDateFormatChange }
                            useMainTodoTitle={ useMainTodoTitle }
                            onUseMainTodoTitleChange={ onUseMainTodoTitleChange }
                            count={ items.length }
                        />

                        <div className="mt-4 overflow-auto" style={{
                            height: `${ POPUP_HEIGHT - 240 }px`,
                        }}>
                            {
                                items.map((item, i) => {
                                    return (
                                        <TodoSplitItem
                                            key={ i }
                                            item={ item }
                                            index={ i }
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

                <TodoSplitButtons
                    save={ save }
                    resetDescription={ resetDescription }
                    split={ split }
                    isLoading={ isLoading }
                />

            </div>
        </Popup>
    )
};

export default TodoSplitPopup;