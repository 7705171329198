import { useState } from 'react';
import { showToast } from '../../../../../common/domain/toast-provider';
import { DeleteIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { IFoodCategory } from '../../../food-interfaces';
import { useFoodStore } from '../../../data/food-store';
import { deleteFoodCategory } from '../../../data/food-data-service';

interface IDeleteFoodCategory {
    category: IFoodCategory;
}

const DeleteFoodCategory = ({ category }: IDeleteFoodCategory) => {

    const [isLoading, setLoading] = useState(false);

    const setFoodCategoriesData = useFoodStore(store => store.setFoodCategoriesData);

    const deleteCategoryHandler = async () => {

        if(!confirm(`Are you sure you want to delete the food category "${ category.name }"?`)) return;

        setLoading(true);
        const response = await deleteFoodCategory(category.id);
        setLoading(false);

        if(!response) {
            showToast('Delete category error.');
            return;
        }

        setFoodCategoriesData(response);
    };

    return (
        <>
            {
                isLoading &&
                <PreloaderIcon size={ 20 } color={ '#717985' } />
            }

            {
                !isLoading &&
                <button
                    title="Delete Food Category"
                    className="food-category__delete flex bg-pink-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
                    onClick={ deleteCategoryHandler }
                    type="button">
                    <DeleteIcon size={ 20 } color="#BE185D" />
                </button>
            }
        </>
    )
};

export default DeleteFoodCategory;