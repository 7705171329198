import { useFinanceStore } from '../../../data/finance-store';
import Business from './business';

const BusinessesList = () => {
    const financeBusinessData = useFinanceStore(store => store.financeBusinessData);

    return (
        <div>
            {
                financeBusinessData?.businesses?.map(business => {
                    return (
                        <Business
                            key={ business.BusinessID }
                            business={ business }
                        />
                    )
                })
            }
        </div>
    )
};

export default BusinessesList;