import { useFoodStore } from '../../../data/food-store';
import FoodLogItem from './food-log-item';
import { getConsumedCalories, groupFoodLogsByDate } from '../../../domain/food-provider';
import { format } from 'date-fns';

const FoodLogList = () => {

    const foodLogData = useFoodStore(store => store.foodLogData);
    const formatted = groupFoodLogsByDate(foodLogData?.foodLogs || []);

    return (
        <div className="my-4">
            {
                formatted.map(item => {

                    const consumed = getConsumedCalories(item.foodLogs)?.calories || 0;

                    return (
                        <div
                            key={ item.date }
                            className="mb-12">

                            <div className="flex items-center">
                                <span className="font-bold mr-3">{ format(item.date, 'HH:mm') }</span>
                                <span className="text-sm bg-stone-100 text-stone-800 px-2 py-1 rounded-md">{ consumed.toFixed(2) } ккал</span>
                            </div>

                            {
                                item.foodLogs?.map(foodLogItem => {
                                    return (
                                        <FoodLogItem
                                            key={ foodLogItem.id }
                                            foodLogItem={ foodLogItem }
                                        />
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
        </div>
    )
};

export default FoodLogList;