import { useState } from 'react';
import { DeleteIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { showToast } from '../../../../../common/domain/toast-provider';
import { IFinanceTransactionItem } from '../../../finance-interfaces';
import { deleteTransaction } from '../../../data/finance-data-service';
import { useFinanceStore } from '../../../data/finance-store';

interface IDeleteTransaction {
    transaction: IFinanceTransactionItem;
}

const DeleteTransaction = ({ transaction }: IDeleteTransaction) => {

    const [isLoading, setLoading] = useState(false);

    const setFinanceTransactionsData = useFinanceStore(store => store.setFinanceTransactionsData);
    const transactionsPageNumber = useFinanceStore(store => store.transactionsPageNumber);
    const transactionsSelectedYear = useFinanceStore(store => store.transactionsSelectedYear);
    const transactionsSelectedMonth = useFinanceStore(store => store.transactionsSelectedMonth);
    const transactionsSelectedCategoryID = useFinanceStore(store => store.transactionsSelectedCategoryID);

    const deleteHandler = async () => {

        if(!confirm(`Are you sure you want to delete transaction "${ transaction.TransactionBusinessName }"?`)) return;

        setLoading(true);
        const response = await deleteTransaction(
            transaction.TransactionID,
            transactionsPageNumber,
            transactionsSelectedYear,
            transactionsSelectedMonth,
            transactionsSelectedCategoryID,
        );
        setLoading(false);

        if(!response) {
            showToast('Delete transaction error.');
            return;
        }

        setFinanceTransactionsData(response);
    };

    return (
        <>
            {
                isLoading &&
                <PreloaderIcon size={ 20 } color={ '#717985' } />
            }

            {
                !isLoading &&
                <button
                    title="Delete Transaction"
                    className="transaction__delete flex bg-pink-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
                    onClick={ deleteHandler }
                    type="button">
                    <DeleteIcon size={ 20 } color="#BE185D" />
                </button>
            }
        </>
    )
};

export default DeleteTransaction;