import { IOverview, IOverviewResponseOld } from '../overview-interfaces';
import { createPaging } from './pagination-provider';

export const formatOverviewData = async (response: IOverviewResponseOld, currentPage: number) : Promise<IOverview|null> => {

    if(!response.todos) return null;

    const total = response.total ?? 0;

    return {
        todos: response.todos,
        total,
        paging: createPaging(total, currentPage),
    };
};