import FoodLogHeader from './header';
import FoodLogList from './list/food-log-list';
import { useFoodStore } from '../../data/food-store';
import { useEffect } from 'react';
import { getFoodLogs } from '../../data/food-data-service';
import { endOfDay, startOfDay } from 'date-fns';
import { showToast } from '../../../../common/domain/toast-provider';
import AddFoodLog from './actions/add-food-log';
import EditFoodLogPopup from './actions/edit-food-log';
import TopBar from './top-bar';

const FoodLogHome = () => {

    const setFoodLogData = useFoodStore(store => store.setFoodLogData);
    const foodLogDate = useFoodStore(store => store.foodLogDate);

    useEffect(() => {
        (async () => {
            const fromDate = startOfDay(foodLogDate).getTime();
            const toDate = endOfDay(foodLogDate).getTime();

            const response = await getFoodLogs(
                fromDate,
                toDate,
            );

            if(!response) {
                showToast('Get food logs error.');
                return;
            }

            setFoodLogData(response);
        })();
    }, [
        foodLogDate,
        setFoodLogData,
    ]);

    return (
        <div>
            <FoodLogHeader />
            <TopBar />
            <FoodLogList />
            <AddFoodLog />
            <EditFoodLogPopup />
        </div>
    )
};

export default FoodLogHome;