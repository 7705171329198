import { ChangeEvent, useState } from 'react';
import { AttachmentIcon, PreloaderIcon } from '../../../../common/ui/icons';
import { showToast } from '../../../../common/domain/toast-provider';
import { sendCloseActionsEvent } from '../../../../common/ui/actions';
import { IFood } from '../../food-interfaces';
import { useFoodStore } from '../../data/food-store';
import { uploadFoodAttachment } from '../../data/food-data-service';

interface IFoodUploadAttachmentButtonProps {
    food: IFood;
}

const FoodUploadAttachmentButton = ({ food }: IFoodUploadAttachmentButtonProps) => {

    const [isLoading, setLoading] = useState(false);

    const setFoodItemsData = useFoodStore(state => state.setFoodItemsData);
    const foodItemsPageNumber = useFoodStore(store => store.foodItemsPageNumber);
    const foodItemsSelectedCategoryID = useFoodStore(store => store.foodItemsSelectedCategoryID);
    const foodItemsSearchTerm = useFoodStore(store => store.foodItemsSearchTerm);

    const uploadFileHandler = async (evt: ChangeEvent<HTMLInputElement>) => {

        if(!evt.target.files || evt.target.files.length <= 0){
            showToast('Upload error.');
            return;
        }

        setLoading(true);

        for(const file of evt.target.files) {

            const formData = new FormData();
            formData.set('file', file);
            formData.set('food_id', food.id.toString());
            formData.set('foodItemsPageNumber', foodItemsPageNumber.toString());
            formData.set('foodItemsSelectedCategoryID', foodItemsSelectedCategoryID.toString());
            formData.set('foodItemsSearchTerm', foodItemsSearchTerm);

            const response = await uploadFoodAttachment(formData as FormData);

            if(!response || !response.isValid) {
                showToast('Upload error.');
                setLoading(false);
                return;
            }

            setFoodItemsData(response);
        }

        setLoading(false);
        showToast('Upload OK.', false);
        sendCloseActionsEvent();
    };

    return (
        <label
            title="Upload File"
            className="food__attachment-btn flex bg-sky-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn">

            {
                !isLoading &&
                <AttachmentIcon size={ 20 } color="#204A6E" />
            }

            {
                isLoading &&
                <PreloaderIcon size={ 20 } color={ '#204A6E' } />
            }

            <input
                onChange={ uploadFileHandler }
                className="hidden"
                type="file"
                multiple={ true }
            />
        </label>
    )
};

export default FoodUploadAttachmentButton;