import { useEffect, MouseEvent } from 'react';
import Menu from '../../../menu/menu';
import { useAuthStatus } from '../../auth/hooks/auth-status-hook';
import Preloader from '../../../common/ui/preloader';
import ProjectMenu from './projects/project-menu';
import { useNavigate, useParams } from 'react-router-dom';
import { getSummary, selectDay, selectProject, selectToday } from '../data/planner-data-service';
import MobileMenuBtn from '../../../menu/ui/mobile-menu-btn';
import ProjectView from './projects/project-view';
import TodayView from './today/today-view';
import {
    combineProjectData,
    getTodoIdFromHash,
    openPathToNestedTodo
} from '../domain/planner-provider';
import DayView from './today/day-view';
import { ListType } from '../planner-interfaces';
import { showToast } from '../../../common/domain/toast-provider';
import SummaryView from './summary/summary-view';
import { usePlannerStore } from '../data/planner-store';
import TodoSplitPopup from './todo/todo-split/todo-split-popup';

const PlannerHome = () => {

    const { loading } = useAuthStatus();

    const navigate = useNavigate();

    const { project_id } = useParams();

    const setListType = usePlannerStore(state => state.setListType);
    const combinedProject = usePlannerStore(state => state.combinedProject);
    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);
    const splitPopupTodo = usePlannerStore(state => state.splitPopupTodo);

    const isTodayView = project_id === 'today' || project_id === undefined;
    const isDayView = project_id?.startsWith('day-');
    const isSummaryView = project_id === 'summary';

    useEffect(() => {
        if(isTodayView) {
            setListType(ListType.TodayView);
            return;
        }

        if(isDayView) {
            setListType(ListType.DayView);
            return;
        }

        if(isSummaryView) {
            setListType(ListType.SummaryView);
            return;
        }

        setListType(ListType.ProjectView);
    }, [isTodayView, isDayView, isSummaryView, setListType]);

    useEffect(() => {
        (async () => {

            if(isTodayView) {
                const todayResponse = await selectToday();
                if(!todayResponse) {
                    showToast('Get today data error.');
                    return;
                }

                setCombinedProject(combineProjectData(todayResponse));
                return;
            }

            if(isDayView) {
                const dayResponse = await selectDay(project_id);
                if(!dayResponse) {
                    showToast('Get day data error.');
                    return;
                }

                setCombinedProject(combineProjectData(dayResponse));
                return;
            }

            if(isSummaryView) {
                const dayResponse = await getSummary();
                if(!dayResponse) {
                    showToast('Get summary data error.');
                    return;
                }

                setCombinedProject(combineProjectData(dayResponse));
                return;
            }

            const projectResponse = await selectProject(Number(project_id) || 0);

            if(!projectResponse) {
                showToast('Get project data error.');
                return;
            }

            setCombinedProject(combineProjectData(projectResponse));
        })();
    }, [project_id, setCombinedProject, navigate, isDayView, isSummaryView, isTodayView]);

    useEffect(() => {

        const onhashchange = () => {
            if(!combinedProject) return;

            const todo_id = getTodoIdFromHash(window.location.hash);
            if(!todo_id) return;

            const shouldUpdate = openPathToNestedTodo(todo_id, combinedProject);

            if(shouldUpdate) {
                try{
                    const copy = JSON.parse(JSON.stringify(combinedProject));
                    setCombinedProject(copy);
                }
                catch (ex) {
                    console.log(ex);
                }
            }

            const $todo = document.getElementById(`todo-${ todo_id }`);
            if(!$todo) return;

            $todo.scrollIntoView();

            const url = new URL(window.location.href);
            url.hash = '';
            history.replaceState('', '', url.toString());
        };

        onhashchange();

        window.addEventListener('hashchange', onhashchange);

        return () => {
            window.removeEventListener('hashchange', onhashchange);
        }
    }, [combinedProject, setCombinedProject]);

    const stopPropagation = (evt: MouseEvent<HTMLDivElement>) => {
        evt.stopPropagation();
    };

    return (
        <div className="text-slate-800">
            { loading && <Preloader/> }

            {
                !loading &&
                <div className="flex min-h-screen relative overflow-hidden">

                    <MobileMenuBtn />

                    <div
                        onClick={ stopPropagation }
                        className="mobile-menu-sidebar flex absolute z-[2000] right-0 translate-x-full transition-transform xl:static xl:translate-x-0">
                        <Menu />
                        <ProjectMenu />
                    </div>

                    <div className="flex flex-col flex-1 leading-7 h-screen relative z-50 bg-white w-screen">
                        <div className="overflow-auto px-6 py-10">
                            { isTodayView && <TodayView/> }

                            { isDayView && <DayView /> }

                            { isSummaryView && <SummaryView /> }

                            {
                                !isTodayView &&
                                !isDayView &&
                                !isSummaryView &&
                                combinedProject?.projects.map(project => {

                                    return (
                                        <ProjectView
                                            key={`project-${ project.project_id }`}
                                            project={ project}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>

                    {
                        splitPopupTodo &&
                        <TodoSplitPopup />
                    }
                </div>
            }
        </div>
    )
};

export default PlannerHome;