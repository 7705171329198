import { ChangeEvent } from 'react';
import { getYearsOptions } from '../../../../../../common/domain/date-time-provider';
import { setTransactionsYearToStorage } from '../../../../domain/finance-storage';

interface ITransactionActionsYearsDropDown {
    transactionsSelectedYear: number;
    setTransactionsSelectedYear: (_transactionsSelectedYear: number) => void;
}

const TransactionActionsYearsDropDown = ({
          transactionsSelectedYear,
          setTransactionsSelectedYear,
      }: ITransactionActionsYearsDropDown) => {

    /**
     * Years are 0-indexed in JavaScript Date.
     */
    const onChange = (evt: ChangeEvent<HTMLSelectElement>) => {
        const value = Number(evt.target.value) || 0;
        setTransactionsSelectedYear(value);
        setTransactionsYearToStorage(value);
    };

    return (
        <select
            className="rounded bg-white border px-4 py-2"
            value={ transactionsSelectedYear }
            onChange={ onChange }>
            { getYearsOptions() }
        </select>
    )
};

export default TransactionActionsYearsDropDown;