import { useEffect } from 'react';
import { formatOverviewData } from '../domain/overview-provider';
import OverviewTodoItem from './overview-todo-item';
import { getOverview } from '../data/overview-data-service';
import { useOverviewStore } from '../data/overview-store';
import ProjectFreeText from './project-free-text';

const OverviewContent = () => {

    const overviewData = useOverviewStore(store => store.overviewData);
    const setOverviewData = useOverviewStore(store => store.setOverviewData);

    useEffect(() => {
        (async () => {
            const response = await getOverview();
            setOverviewData(formatOverviewData(response));
        })();
    }, [setOverviewData]);

    return (
        <div>
            <div className="grid md:grid-cols-2 lg:grid-cols-4 border-l border-t">
                {
                    overviewData?.projects.map(project => {
                        const todosCount = project.todosCount;

                        const completedTodosCount = project.completedTodosCount ?? 0;
                        const completedPercent = Math.round(completedTodosCount * 100 / todosCount);

                        const nonCompletedTodos = todosCount - completedTodosCount;
                        const nonCompletedPercent = Math.round(nonCompletedTodos * 100 / todosCount);

                        return (
                            <div
                                key={ project.project_id }
                                className="border-r border-b p-4">
                                <div className="font-bold mb-2">{ project.project_title }</div>

                                <div className="text-sm grid gap-2 grid-cols-3 items-center">
                                    <span className="mr-2">Not Completed:</span>
                                    <span className="bg-red-100 rounded p-1 mr-2 text-center">{ nonCompletedPercent }%</span>
                                    <span>{ nonCompletedTodos } / { todosCount }</span>

                                    <span className="mr-2">Completed:</span>
                                    <span className="bg-green-100 rounded p-1 mr-2 text-center">{ completedPercent }%</span>
                                    <span>{ completedTodosCount } / { todosCount }</span>

                                    <span className="mr-2">Sections:</span>
                                    <span className="text-center">{ project.sectionsCount } &nbsp;&nbsp;&nbsp;</span>
                                    <span></span>

                                    <div className="col-start-1 col-end-4">
                                        {
                                            project.sections[0].todos.map((todo, index) => {
                                                return (
                                                    <OverviewTodoItem
                                                        key={ todo.todo_id }
                                                        index={ index }
                                                        todo={ todo }
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                </div>



                                <ProjectFreeText project={ project }/>
                            </div>
                        )
                    })
                }
            </div>

        </div>
    )
};

export default OverviewContent;