import { IFoodLog } from '../../../food-interfaces';
import Actions, { sendCloseActionsEvent } from '../../../../../common/ui/actions';
import DeleteFoodLog from '../actions/delete-food-log';
import { EditIcon } from '../../../../../common/ui/icons';
import { useFoodStore } from '../../../data/food-store';
import { markdown2Html } from '../../../../../common/markdown/markdown-provider';
import { checkIsImage } from '../../../../../common/domain/file-provider';

interface IFoodLogItemProps {
    foodLogItem: IFoodLog;
}

const FoodLogItem = ({ foodLogItem }: IFoodLogItemProps) => {

    const setFoodLogEditPopup = useFoodStore(store => store.setFoodLogEditPopup);

    const startEdit = () => {
        setFoodLogEditPopup(foodLogItem);
        sendCloseActionsEvent();
    };

    return (
       <div>
           <div className="mb-4 flex items-center justify-between border-b pb-2 mb-2">
               <div className="flex items-center">

                   <button
                       className="text-left mr-4"
                       onClick={ startEdit }
                       type="button">
                       { foodLogItem.name }
                   </button>

                   <div className="text-sm mr-4">
                       <span className="mr-4">x</span>
                       <span>{ foodLogItem.quantity }</span>
                       {/*<span>{ foodLogItem.quantity === 1 ? 'item' : 'items' }</span>*/}
                   </div>

                   <div className="text-xs bg-stone-100 text-stone-800 px-2 py-1 rounded-md mr-4">
                       { Math.round(foodLogItem.calories * foodLogItem.quantity) } ккал
                   </div>

                   {
                       foodLogItem.attachment_path &&
                       checkIsImage(foodLogItem.attachment_path) &&
                       <a
                           className="attachment__box flex flex-col"
                           href={ `/api/food/attachment/get?path=${ foodLogItem.attachment_path }` }
                           rel="noreferrer"
                           target="_blank"
                           style={{
                               width: '50px',
                           }}>
                           <img
                               className="rounded max-w-full"
                               src={ `/api/food/attachment/get?path=${ foodLogItem.attachment_path }` }
                               alt=""
                           />
                       </a>
                   }
               </div>

               <Actions classes="food-log__actions" gridSize={ 2 } mr="mr-0" ml="ml-0" pl="pl-0">

                   <DeleteFoodLog foodLog={ foodLogItem } />

                   <button
                       title="Edit Food Log"
                       className="food-log_edit flex bg-slate-200 rounded mx-1 text-left justify-center items-center whitespace-nowrap action-btn"
                       onClick={ startEdit }
                       type="button">
                       <EditIcon color={ '#6e7781' } size={ 20 } />
                   </button>
               </Actions>
           </div>

           {
               foodLogItem.description &&
               <div
                    className="text-sm markdown-editor"
                    dangerouslySetInnerHTML={{__html: markdown2Html(foodLogItem.description ?? '') }} />
           }
       </div>
    )
};

export default FoodLogItem;