import SearchBusinesses from './actions/search';
import { useEffect } from 'react';
import { getFinanceBusinessData } from '../../data/finance-data-service';
import { useFinanceStore } from '../../data/finance-store';
import { showToast } from '../../../../common/domain/toast-provider';
import BusinessesList from './list/businesses-list';
import BusinessesPagination from './list/pagination';
import BusinessTabsNavigation, { BusinessesTab } from './tabs-navigation';
import AddNewBusiness from './actions/add-new-business';

const BusinessesHome = () => {

    const businessesPageNumber = useFinanceStore(store => store.businessesPageNumber);
    const businessSearchTerm = useFinanceStore(store => store.businessSearchTerm);
    const financeBusinessData = useFinanceStore(store => store.financeBusinessData);
    const setFinanceBusinessData = useFinanceStore(store => store.setFinanceBusinessData);
    const businessesTab = useFinanceStore(store => store.businessesTab);

    useEffect(() => {
        (async () => {
            const response = await getFinanceBusinessData(businessesPageNumber, businessSearchTerm);

            if(!response) {
                showToast('Get businesses error.');
                return;
            }

            setFinanceBusinessData(response);
        })();
    }, [businessSearchTerm, businessesPageNumber, setFinanceBusinessData]);

    return (
        <div>
            <h1 className="flex items-center text-xl xl:text-3xl mb-6">
                Businesses
            </h1>

            <BusinessTabsNavigation />

            {
                businessesTab === BusinessesTab.Default &&
                <>
                    <SearchBusinesses />

                    {
                        financeBusinessData?.businesses?.length > 0 &&
                        <>
                            <BusinessesList />
                            <BusinessesPagination />
                        </>
                    }
                </>
            }

            {
                businessesTab === BusinessesTab.AddNew &&
                <AddNewBusiness />
            }

        </div>
    )
};

export default BusinessesHome;