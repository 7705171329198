import { useFinanceStore } from '../../../data/finance-store';
import TransactionItem from './transaction-item';

const TransactionsList = () => {

    const financeTransactionsData = useFinanceStore(store => store.financeTransactionsData);

    return (
        <div className="w-full overflow-auto">
            <table className="w-full border border-collapse mb-3">
                <thead>
                <tr>
                    <th className="border px-4 py-2">&nbsp;</th>
                    <th className="border px-4 py-2 text-left">Date</th>
                    <th className="border px-4 py-2 text-left">₪</th>
                    <th className="border px-4 py-2 text-left">Business Name</th>
                    <th className="border px-4 py-2 text-left">Payments</th>
                    <th className="border px-4 py-2 text-left">Source</th>
                    <th className="border px-4 py-2 text-left">Category</th>
                    <th className="border px-4 py-2 text-left">Conf. #</th>
                    <th className="border px-4 py-2">&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                {
                    financeTransactionsData?.transactions.map(
                        (transaction, i) => {

                            return (
                                <TransactionItem
                                    key={ transaction.TransactionID }
                                    transaction={ transaction }
                                    index={ i }
                                />
                            );
                        })

                }
                </tbody>
            </table>
        </div>
    )
};

export default TransactionsList;