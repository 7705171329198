import { deleteJson, get, postJson, putJson } from '../../../common/data/common-data-service';
import {
    IFinanceBusinessGeneralResponse,
    IFinanceCategoriesResponse, IFinanceTransactionItem,
    IFinanceTransactionsResponse, TransactionSource
} from '../finance-interfaces';
import { ITEMS_PER_PAGE } from '../ui/businesses/list/pagination';

// --------------- BUSINESS -----------------------

export const getFinanceBusinessData = async (pageNumber: number, searchText: string) : Promise<IFinanceBusinessGeneralResponse|null> => {
    return await get(`/api/finance/business/get-data?pageNumber=${ pageNumber }&itemsOnPage=${ ITEMS_PER_PAGE }&searchText=${ searchText }`);
};

export const insertBusiness = async (
    name: string,
    desc: string,
    categoryID: number,
) : Promise<IFinanceBusinessGeneralResponse|null> => {
    return await postJson(`/api/finance/business/insert`, {
        name,
        desc,
        categoryID,
    });
};

export const updateBusiness = async (
    businessID: number,
    name: string,
    desc: string,
    categoryID: number,
) : Promise<IFinanceBusinessGeneralResponse|null> => {
    return await putJson(`/api/finance/business/update`, {
        businessID,
        name,
        desc,
        categoryID,
    });
};

export const deleteBusiness = async (businessID: number) : Promise<IFinanceBusinessGeneralResponse|null> => {
    return await deleteJson(`/api/finance/business/delete`, {
       businessID
    });
};

// --------------- CATEGORIES -----------------------

export const getFinanceCategoriesData = async () : Promise<IFinanceCategoriesResponse|null> => {
    return await get(`/api/finance/category/get-data`);
};

export const deleteCategory = async (categoryID: number) : Promise<IFinanceCategoriesResponse|null> => {
    return await deleteJson(`/api/finance/category/delete`, {
        categoryID
    });
};

export const insertCategory = async (
    name: string,
    desc: string,
    color: string,
) : Promise<IFinanceCategoriesResponse|null> => {
    return await postJson(`/api/finance/category/insert`, {
        name,
        desc,
        color,
    });
};

export const updateCategory = async (
    categoryID: number,
    name: string,
    desc: string,
    color: string,
) : Promise<IFinanceCategoriesResponse|null> => {
    return await putJson(`/api/finance/category/update`, {
        categoryID,
        name,
        desc,
        color,
    });
};

// --------------- TRANSACTIONS -----------------------

export const getFinanceTransactionsData = async (
    pageNumber: number,
    year: number,
    month: number,
    categoryID: number,
    transactionsSearchTerm: string
) : Promise<IFinanceTransactionsResponse|null> => {
    return await get(`/api/finance/transaction/get-data?pageNumber=${ pageNumber }&itemsOnPageNumber=${ ITEMS_PER_PAGE }&year=${ year }&month=${ month }&categoryID=${ categoryID }&searchTerm=${ transactionsSearchTerm }`);
};

export const insertTransaction = async (
    pageNumber: number,
    year: number,
    month: number,
    categoryID: number,

    transactionDay: number,
    transactionMonth: number,
    transactionYear: number,

    transactionBusinessName: string,
    transactionDescription: string,
    transactionConfirmationNumber: string,

    transactionAmount: number,
    transactionSource: TransactionSource,
    transactionPaymentNumber: number,
    transactionPaymentsNumber: number,

) : Promise<IFinanceTransactionsResponse|null> => {
    return await postJson(`/api/finance/transaction/insert`, {
        pageNumber,
        itemsOnPageNumber: ITEMS_PER_PAGE,
        year,
        month,
        categoryID,

        transactionDay,
        transactionMonth,
        transactionYear,

        transactionBusinessName,
        transactionDescription,
        transactionConfirmationNumber,

        transactionAmount,
        transactionSource,
        transactionPaymentsNumber,
        transactionPaymentNumber,
    });
};

export const updateTransaction = async (
    transactionID: number,

    pageNumber: number,
    year: number,
    month: number,
    categoryID: number,

    transactionDay: number,
    transactionMonth: number,
    transactionYear: number,

    transactionBusinessName: string,
    transactionDescription: string,
    transactionConfirmationNumber: string,

    transactionAmount: number,
    transactionSource: number,
    transactionPaymentNumber: number,
    transactionPaymentsNumber: number,

) : Promise<IFinanceTransactionsResponse|null> => {
    return await putJson(`/api/finance/transaction/update`, {
        transactionID,

        pageNumber,
        itemsOnPageNumber: ITEMS_PER_PAGE,
        year,
        month,
        categoryID,

        transactionDay,
        transactionMonth,
        transactionYear,

        transactionBusinessName,
        transactionDescription,
        transactionConfirmationNumber,

        transactionAmount,
        transactionSource,
        transactionPaymentsNumber,
        transactionPaymentNumber,
    });
};

export const deleteTransaction = async (
    transactionID: number,
    pageNumber: number,
    year: number,
    month: number,
    categoryID: number,
) : Promise<IFinanceTransactionsResponse|null> => {
    return await deleteJson(`/api/finance/transaction/delete`, {
        transactionID,
        pageNumber,
        itemsOnPageNumber: ITEMS_PER_PAGE,
        year,
        month,
        categoryID,
    });
};

export const insertBulkTransactions = async (
    transactions: IFinanceTransactionItem[],
    pageNumber: number,
    year: number,
    month: number,
    categoryID: number,
) : Promise<IFinanceTransactionsResponse|null> => {
    return await postJson(`/api/finance/transaction/insert-bulk`, {
        transactions,
        pageNumber,
        itemsOnPageNumber: ITEMS_PER_PAGE,
        year,
        month,
        categoryID,
    });
};