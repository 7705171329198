import { useFinanceStore } from '../../../../data/finance-store';
import CategoriesRadioFilter from './categories-radio-filter';

const CategoriesRadioFilters = () => {

    const financeTransactionsData = useFinanceStore(store => store.financeTransactionsData);

    return (
        <div className="mt-2 mb-6 text-sm grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">

            <CategoriesRadioFilter
                categoryID={ -1 }
                categoryName="All"
                categoryAmount={ 0 }
                categoryColor={ '#efefef' }
            />

            {
                financeTransactionsData?.categories.map(category => {
                    return (
                        <CategoriesRadioFilter
                            key={ category.CategoryID }
                            categoryID={ category.CategoryID }
                            categoryName={ category.CategoryName }
                            categoryAmount={ category.Amount }
                            categoryColor={ category.CategoryColor }
                        />
                    )
                })
            }
        </div>
    )
};

export default CategoriesRadioFilters;