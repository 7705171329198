import { markdown2Html } from '../../../common/markdown/markdown-provider';
import { NestedIcon } from '../../../common/ui/icons';
import { ITodo, TodoType } from '../../planner/planner-interfaces';
import Actions from '../../../common/ui/actions';
import OpenTodoLink from '../../planner/ui/todo/actions/open-todo-link';
import RemoveTodoFromOverviewBtn from './actions/remove-todo-from-overview';

interface IOverviewTodoItem {
    todo: ITodo;
    index: number;
}

const getNumerClassesPerTodoType = (todo: ITodo) => {
    switch (todo.todo_type) {
        case TodoType.Problem: {
            return 'bg-red-100 border-red-200 text-red-900';
        }
        case TodoType.Goal: {
            return 'bg-green-100 border-green-200 text-green-900';
        }
    }

    return 'bg-amber-100 border-amber-200 text-amber-900'; // return 'bg-gray-100 border-gray-200';
};

const OverviewTodoItem = ({ todo, index }: IOverviewTodoItem) => {
    return (
        <div className={ `border-t pt-2 mt-2 ${ index === 0 ? 'border-green-500' : '' }` }>
            <div className="flex items-start">

                <div className={ `mr-4 px-1 rounded flex items-center justify-center border text-xs ${ getNumerClassesPerTodoType(todo) }` }>{ index + 1 }</div>

                <div dangerouslySetInnerHTML={{__html: markdown2Html(todo.todo_title) }} />

                <Actions classes="overview-todo-item__actions" mr="mr-0" gridSize={ 2 }>
                    <RemoveTodoFromOverviewBtn todo={ todo } />
                    <OpenTodoLink todo={ todo } />
                </Actions>
            </div>

            <div className="text-xs flex items-center mr-2 whitespace-nowrap ml-8">
                <NestedIcon classes="mr-[2px]" />
                { todo.nested_completed_count } / { todo.nested_count }
            </div>
        </div>
    )
};

export default OverviewTodoItem;