import Popup from '../../../../../common/ui/popup';
import { ChangeEvent, KeyboardEvent as ReactKeyboardEvent, useEffect, useRef, useState } from 'react';
import { showToast } from '../../../../../common/domain/toast-provider';
import { PreloaderIcon } from '../../../../../common/ui/icons';
import { useFoodStore } from '../../../data/food-store';
import { endOfDay, format, startOfDay } from 'date-fns';
import { updateFoodLog } from '../../../data/food-data-service';
import { MDXEditor, MDXEditorMethods } from '@mdxeditor/editor';
import FoodDropDown from '../../foods/food-drop-down';
import { getCompactMarkdownEditorPlugins } from '../../../../../common/markdown/markdown-editor-provider';

const EditFoodLogPopup = () => {

    const editorRef = useRef<MDXEditorMethods>(null);

    const foodLogEditPopup = useFoodStore(store => store.foodLogEditPopup);
    const setFoodLogEditPopup = useFoodStore(store => store.setFoodLogEditPopup);

    const foodLogDate = useFoodStore(store => store.foodLogDate);
    const setFoodLogData = useFoodStore(store => store.setFoodLogData);

    const [isLoading, setLoading] = useState(false);

    const [date, setDate] = useState<number|null>(Date.now());
    const [dateTouched, setDateTouched] = useState(false);
    const isDateValid = !dateTouched || date > 0;

    const [foodId, setFoodId] = useState(0);
    const [foodIdTouched, setFoodIdTouched] = useState(false);
    const isFoodIdValid = !foodIdTouched || foodId > 0;

    const [quantity, setQuantity] = useState(0);
    const [quantityTouched, setQuantityTouched] = useState(false);
    const isQuantityValid = !quantityTouched || quantity > 0;

    useEffect(() => {
        if(!foodLogEditPopup) return;

        setDate(foodLogEditPopup.date);
        setDateTouched(false);

        setFoodId(foodLogEditPopup.food_id);
        setFoodIdTouched(false);

        setQuantity(foodLogEditPopup.quantity);
        setQuantityTouched(false);

    }, [foodLogEditPopup]);

    const close = () => {
        setFoodLogEditPopup(null);
    };

    const save = async () => {

        if(foodId <= 0) {
            setFoodIdTouched(true);
            return;
        }

        if(date <= 0) {
            setDateTouched(true);
            return;
        }

        if(quantity <= 0) {
            setQuantityTouched(true);
            return;
        }

        setLoading(true);

        const desc = editorRef.current?.getMarkdown() || '';
        const fromDate = startOfDay(foodLogDate).getTime();
        const toDate = endOfDay(foodLogDate).getTime();

        const response = await updateFoodLog(
            fromDate,
            toDate,

            foodLogEditPopup.id,
            foodId,
            date,
            quantity,
            desc.trim(),
        );

        setLoading(false);

        if(!response) {
            showToast('Edit food log error.');
            return;
        }

        close();
        setFoodLogData(response);
    };

    const onDateChange = (evt: ChangeEvent<HTMLInputElement>) => {
        if(!evt.target.value) {
            setDate(null);
            return;
        }

        const _date = new Date(evt.target.value);
        setDate(_date.getTime());
    };

    const onQuantityChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const value = Number(evt.target.value) || 0;
        setQuantity(value);
        setQuantityTouched(true);
    };

    const onKeyDown = async (evt: ReactKeyboardEvent<HTMLInputElement>) => {

        if(evt.code === 'Escape') {
            close();
        }

        if(evt.code === 'Enter') {
            await save();
        }
    };

    return (
        <>
            {
                foodLogEditPopup &&
                <>
                    <Popup onClose={ close } width={ 700 } height={ 500 }>
                        <div className="p-4 flex flex-col overflow-auto text-left font-normal text-gray-900">

                            <label className="flex flex-col mb-4">
                                <div className="font-bold text-slate-400 mb-2">Food Type</div>
                                <FoodDropDown
                                    foodId={ foodId }
                                    setFoodId={ setFoodId }
                                />

                                {
                                    !isFoodIdValid && <div className="text-red-700 text-xs mt-1">The food type is required.</div>
                                }
                            </label>

                            <div className="grid grid-cols-2 gap-x-4 gap-y-2 mb-4">

                                <label
                                    className="font-bold text-slate-400"
                                    htmlFor="food-log-date">Date</label>

                                <label
                                    className="font-bold text-slate-400"
                                    htmlFor="food-log-quantity">Quantity</label>

                                <div>
                                    <input
                                        id="food-log-date"
                                        type="datetime-local"
                                        className="border rounded text-slate-800 outline-stone-200 px-4 py-2"
                                        onKeyDown={ onKeyDown }
                                        onChange={ onDateChange }
                                        value={ date ? format(date, `yyyy-MM-dd'T'HH:mm`) : '' }
                                    />

                                    {
                                        !isDateValid && <div className="text-red-700 text-xs mt-1">The date is required.</div>
                                    }
                                </div>


                                <div className="flex flex-col">
                                    <input
                                        id="food-log-quantity"
                                        value={ quantity }
                                        onInput={ onQuantityChange }
                                        onKeyDown={ onKeyDown }
                                        className={ `border rounded px-4 py-2 ${ isQuantityValid ? 'outline-stone-200' : 'outline-red-200 border-red-200' }` }
                                        type="number"
                                    />

                                    {
                                        !isQuantityValid && <div className="text-red-700 text-xs mt-1">The quantity is required.</div>
                                    }
                                </div>
                            </div>

                            <label className="font-bold flex flex-col text-slate-400 mb-2">Description</label>
                            <div className="markdown-editor border rounded-lg mb-4">
                                <MDXEditor
                                    ref={ editorRef }
                                    markdown={ '' }
                                    plugins={ getCompactMarkdownEditorPlugins() }
                                />
                            </div>

                            <div className="flex items-center justify-end text-sm mt-4">
                                <button
                                    className="bg-stone-400 text-slate-100 rounded px-4 py-2 mr-2"
                                    onClick={ close }
                                    type="button">Cancel</button>

                                {
                                    isLoading &&
                                    <PreloaderIcon size={ 24 } color={ '#717985' } />
                                }

                                {
                                    !isLoading &&
                                    <button
                                        className="bg-slate-500 text-slate-100 rounded px-6 py-2"
                                        onClick={ save }
                                        type="button">Save</button>
                                }
                            </div>

                        </div>
                    </Popup>
                </>
            }
        </>
    )
};

export default EditFoodLogPopup;